import Banner5 from "components/elements/Banner5"
import React from "react"

function SubBanner() {
  return (
    <section className="banners mb-25 mt-25">
      <div className="container">
        <div className="row">
          <Banner5 />
        </div>
      </div>
    </section>
  )
}

export default SubBanner
