import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useWindowSize } from "react-use"
import PropTypes from "prop-types"

function ProductListLoader({ className }) {
  const { width } = useWindowSize()

  return (
    <>
      <Skeleton
        containerClassName={`row d-flex justify-content-center  align-items-center ${className}`}
        count={width < 1024 ? 4 : 8}
        className="col-md-3 col-2  mb-3 mx-1"
        style={{ height: "250px", width: width >= 480 ? "250px" : "160px" }}
        duration={2}
      />
    </>
  )
}

export default ProductListLoader
ProductListLoader.propTypes = {
  className: PropTypes.any,
}
