import React from "react"
import Layout from "components/layout/Layout"
import BlogGrid from "components/elements/BlogGrid"

function AllBlogList() {
  return (
    <>
      <Layout parent="Home" sub="Blogs" subChild="">
        <section className="mt-50 mb-50 sm:min-h-60">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12">
                <div className="loop-grid pr-30">
                  <BlogGrid show={10} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default AllBlogList
