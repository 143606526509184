import {
  GET_BLOGS,
  GET_BLOGS_FAIL,
  GET_BLOGS_SUCCESS,
  GET_BLOG_DETAILS,
  GET_BLOG_DETAILS_FAIL,
  GET_BLOG_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  blogs: [],
  error: {},
  loading: false,
  blogDetails: {},
}

const Blogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BLOGS:
    case GET_BLOG_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload,
        error: {},
        loading: false,
      }
    case GET_BLOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BLOG_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        blogDetails: action.payload,
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Blogs
