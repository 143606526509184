import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCardOne from 'components/ecommerce/productCards/ProductCardOne';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import { useLocation } from 'react-router-dom';

SwiperCore.use([Navigation]);

function ProductVariant({ data }) {
  const { width } = useWindowSize();
  const location = useLocation();

  const relatedProd = data?.productVariants;

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 1.3;
    } else if (width < 640) {
      return 2.5;
    } else if (width < 768) {
      return 3.5;
    } else return 5.5;
  };

  return (
    <div className="row p-4 related-product-wrap">
      <div className="col-12">
        <h2
          className="related-head font-w-600"
          style={{ textAlign: data?.textAlign }}
        >
          {data?.title}
        </h2>
        <div className="row related-products position-relative">
          <>
            <Swiper
              slidesPerView={handleWindowSizeVsSliderPreview()}
              spaceBetween={10}
              grid={{
                rows: 2,
              }}
              keyboard={{ enabled: true }}
              direction="horizontal"
              navigation={{
                prevEl: '.custom_prev_n',
                nextEl: '.custom_next_n',
              }}
              autoplay={true}
              speed={600}
              className=""
              breakpoints={{
                480: {
                  slidesPerView: 1.3,
                },
                640: {
                  slidesPerView: 2.5,
                },
                768: {
                  slidesPerView: 3.5,
                },
                1024: {
                  slidesPerView: 5.5,
                },
              }}
            >
              {relatedProd?.map((product, i) => (
                <SwiperSlide key={i}>
                  <ProductCardOne product={product} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="slider-arrow slider-arrow-2 carausel-6-columns-arrow">
              <span className="slider-btn slider-prev slick-arrow custom_prev_n">
                <i className="fi-rs-angle-left"></i>
              </span>
              <span className="slider-btn slider-next slick-arrow custom_next_n">
                <i className="fi-rs-angle-right"></i>
              </span>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default ProductVariant;

ProductVariant.propTypes = {
  data: PropTypes.any,
};
