import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useWindowSize } from "react-use"
import PropTypes from "prop-types"

function ProductLoader({ height, innerClass }) {
  const { width } = useWindowSize()

  return (
    <>
      <Skeleton
        containerClassName={"d-flex mt-4 container"}
        count={width > 768 ? 3 : 1}
        className={"mb-3 w-100 m-auto " + `${innerClass}`}
        style={{ height: height }}
        duration={2}
      />
    </>
  )
}

export default ProductLoader

ProductLoader.propTypes = {
  height: PropTypes.string,
  innerClass: PropTypes.string,
}
