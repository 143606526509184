import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { getBlogs } from "store/actions"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import { useWindowSize } from "react-use"
import InfiniteScroll from "react-infinite-scroll-component"
import useCloudinary from "constants/UseCloudinaryImage"

const BlogGrid = ({ show, wide }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()

  const { blogs, loading } = useSelector(state => ({
    blogs: state.Blogs.blogs,
    loading: state.Blogs.loading,
  }))
  const [paginatedBlog, setPaginatedBlog] = useState([])

  const handleSingleViewClick = blog => {
    const removeSpace = blog?.title?.replace(/ /g, "-")

    history.push({
      pathname: `/blog/${removeSpace}`,
      state: {
        ...location.query,
        blogId: blog?._id,
      },
    })
  }

  console.log("blogs", blogs)

  //pagination
  const limit = 10
  const allpages = blogs?.total / limit
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }

  useEffect(() => {
    console.log(blogs?.blogs)
    if (blogs?.blogs !== undefined) {
      const allBlog = [...paginatedBlog, ...blogs?.blogs]
      setPaginatedBlog(allBlog)
    }
  }, [blogs?.blogs])

  const ids = paginatedBlog?.map(o => o._id)

  const filteredFinalCat = paginatedBlog.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  useEffect(() => {
    dispatch(getBlogs("", pages))
  }, [dispatch, pages])

  return (
    <InfiniteScroll
      dataLength={filteredFinalCat?.length ?? 0}
      next={HandlefetchNextPage}
      hasMore={true}
      loader={loading && <ProductListLoader className="isMoblieCate" />}
    >
      <div className="row">
        <>
          {filteredFinalCat?.map((item, i) => (
            <article
              className={
                wide
                  ? "col-xl-3 col-lg-3 col-md-4 col-sm-3 col-12 text-center hover-up mb-30 animated"
                  : "col-xl-3 col-lg-3 col-md-4 col-sm-3 col-12 text-center hover-up mb-30 animated"
              }
              key={i}
            >
              <div className="post-thumb">
                <span
                  className="cursor-pointer"
                  onClick={() => handleSingleViewClick(item)}
                >
                  <img
                    className="border-radius-15 w-100 h-100"
                    src={
                      item?.images?.length > 0
                        ? useCloudinary(item?.images[0]?.url, "w_500")
                        : ""
                    }
                    alt=""
                  />
                </span>
              </div>
              <div className="entry-content-2 sm:p-0">
                <h4 className="post-title mb-0 sm:font-size-14 ">
                  {item?.title}
                </h4>
                {width >= 480 && (
                  <div className="entry-meta font-xs color-grey mt-10 pb-10">
                    <div>
                      <span className="post-on mr-10">
                        {item?.description?.slice(0, 70)}&nbsp;
                        {item?.description?.length > 70 && "..."}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </article>
          ))}
        </>
      </div>
    </InfiniteScroll>
  )
}

export default BlogGrid

BlogGrid.propTypes = {
  show: PropTypes.any,
  wide: PropTypes.any,
}
