import CategoryTab from "components/ecommerce/categoryTab"
import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useBanner } from "./useBannerData"
import useCloudinary from "constants/UseCloudinaryImage"

function ProductG1({ groupDetail }) {
  const product = groupDetail?.products
  const history = useHistory()

  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  const CollectionLink = handleBannerData[0]?.primaryLang?.title?.replace(
    / /g,
    "-"
  )

  const CategoryLink = handleBannerData[0]?.primaryLang?.name?.replace(
    / /g,
    "-"
  )

  const handleRouteLink = () => {
    switch (groupDetail?.type) {
      case "single collection":
        return history.push(`/collections/${CollectionLink}`)
      case "single category":
        return history.push({
          pathname: "/shop-list",
          query: {
            cat: CategoryLink,
            isProduct: false,
          },
        })

      default:
        break
    }
  }

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <div className="section-title mb-4 mt-4 style-2 wow animate__animated animate__fadeIn ">
            <h3 className="fontPlayfair">{groupDetail?.primaryLang?.title} </h3>
            {/* <ul className="nav nav-tabs links" id="myTab">
              <li className="nav-item">
                <button
                  className="nav-link active"
                  id="nav-tab-one"
                  type="button"
                >
                  All
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" id="nav-tab-two" type="button">
                  Milks & Dairies
                </button>
              </li>
            </ul> */}
          </div>
          {groupDetail?.images[0]?.url && (
            <div className="mobileBanner single-hero-slider single-animation-wrap p-5 w-100 mb-0 border-radius-15 background_image">
              <img
                src={useCloudinary(groupDetail?.images[0]?.url, "w_1500")}
                onClick={handleRouteLink}
                className="border-radius-5 h:300px"
              />
            </div>
            
          )}

          <CategoryTab products={product} noHead={true} />
        </div>
      </div>
    </section>
  )
}

export default ProductG1

ProductG1.propTypes = {
  groupDetail: PropTypes.object,
}
