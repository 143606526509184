import { map, range } from "lodash"
import React from "react"
import ContentLoader from "react-content-loader"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"

function GroupLoader({ count, mobileCount, myWidth }) {
  const { width } = useWindowSize()

  return (
    <div className="d-flex flex-wrap">
      {map(range(width > 768 ? count : mobileCount ?? 2), item => (
        <ContentLoader
          key={item}
          viewBox="0 0 900 507"
          height={"100%"}
          width={myWidth ? myWidth : "50%"}
        >
          <rect x="30" y="20" rx="0" ry="0" width="130" height="23" />
          <rect x="30" y="60" rx="0" ry="0" width="200" height="120" />
          <rect x="30" y="189" rx="0" ry="0" width="200" height="15" />
          <rect x="30" y="211" rx="0" ry="0" width="140" height="15" />
          <rect x="243" y="60" rx="0" ry="0" width="200" height="120" />
          <rect x="243" y="189" rx="0" ry="0" width="200" height="15" />
          <rect x="243" y="211" rx="0" ry="0" width="140" height="15" />
          <rect x="455" y="60" rx="0" ry="0" width="200" height="120" />
          <rect x="455" y="189" rx="0" ry="0" width="200" height="15" />
          <rect x="455" y="211" rx="0" ry="0" width="140" height="15" />
          <rect x="667" y="60" rx="0" ry="0" width="200" height="120" />
          <rect x="667" y="188" rx="0" ry="0" width="200" height="15" />
          <rect x="667" y="209" rx="0" ry="0" width="140" height="15" />
          <rect x="30" y="280" rx="0" ry="0" width="130" height="23" />
          <rect x="30" y="320" rx="0" ry="0" width="200" height="120" />
          <rect x="30" y="450" rx="0" ry="0" width="200" height="15" />
          <rect x="30" y="474" rx="0" ry="0" width="140" height="15" />
          <rect x="243" y="320" rx="0" ry="0" width="200" height="120" />
          <rect x="455" y="320" rx="0" ry="0" width="200" height="120" />
          <rect x="667" y="320" rx="0" ry="0" width="200" height="120" />
          <rect x="243" y="450" rx="0" ry="0" width="200" height="15" />
          <rect x="455" y="450" rx="0" ry="0" width="200" height="15" />
          <rect x="667" y="450" rx="0" ry="0" width="200" height="15" />
          <rect x="243" y="474" rx="0" ry="0" width="140" height="15" />
          <rect x="455" y="474" rx="0" ry="0" width="140" height="15" />
          <rect x="667" y="474" rx="0" ry="0" width="140" height="15" />
        </ContentLoader>
      ))}
    </div>
  )
}

export default GroupLoader

GroupLoader.propTypes = {
  count: PropTypes.number,
  mobileCount: PropTypes.number,
  myWidth: PropTypes.string,
}
