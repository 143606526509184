import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"
import useCloudinary from "components/hooks/useCloudinaryImage"
import useWindow from "components/hooks/useWindow"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"

SwiperCore.use([Navigation])

function Carousel({ data, className }) {
  const { width } = useWindowSize()
  const { isMobile } = useWindow()
  const history = useHistory()

  const banners = data?.images

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return handlePreviewCount(1)
    } else if (width < 640) {
      return handlePreviewCount(2)
    } else if (width < 768) {
      return handlePreviewCount(data?.carouselCount)
    } else return handlePreviewCount(data?.carouselCount)
  }

  const handlePreviewCount = maxCount => {
    if (banners?.length < maxCount) {
      return banners?.length
    } else return maxCount
  }

  const web = (
    // mt-60 mb-60
    <div className={"mobileCarousel" + " row " + className}>
      <div className="col-12">
        <div className="row related-products position-relative">
          <Swiper
            slidesPerView={handleWindowSizeVsSliderPreview()}
            spaceBetween={0}
            grid={{
              rows: 2,
            }}
            keyboard={{ enabled: true }}
            direction="horizontal"
            autoplay={true}
            speed={600}
            className=""
            breakpoints={{
              480: {
                slidesPerView: handlePreviewCount(1),
              },
              640: {
                slidesPerView: handlePreviewCount(2),
              },
              768: {
                slidesPerView: handlePreviewCount(data?.carouselCount),
              },
              1024: {
                slidesPerView: handlePreviewCount(data?.carouselCount),
              },
            }}
          >
            {banners?.map((item, i) => (
              <SwiperSlide key={i}>
                <div
                  style={{
                    borderRadius: 0,
                    maxHeight: data?.height + "vh",
                    height: data?.height
                      ? data?.height + "vh"
                      : isMobile
                      ? "auto"
                      : "260px",
                  }}
                  className="banner-img mobileContainer wow animate__animated animate__fadeInUp  w-100"
                  onClick={() => (data?.link ? history.push(data?.link) : "")}
                >
                  <span className="cursor-pointer">
                    <LazyLoad height={200}>
                      <img
                        src={useCloudinary(
                          item?.url ?? "",
                          isMobile ? "w_1000" : "w_1900,q_70"
                        )}
                        alt="bannerImg"
                        className="fit-cover h-100 w-100 object-start mobileBanner "
                        style={{ borderRadius: 0 }}
                        height={"100%"}
                        width={"100%"}
                        title="bannerImg"
                        loading="eager"
                      />
                    </LazyLoad>
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default Carousel

Carousel.propTypes = {
  data: PropTypes.any,
  className: PropTypes.string,
}
