import HomePagesGrid from "components/elements/HomePagesGrid"
import Layout from "components/layout/Layout"
import React from "react"
import { useSelector } from "react-redux"
import MetaData from "helpers/metaData"

function AllPages() {
  const { company } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
  }))

  return (
    <>
      <MetaData
        content={"Scorio"}
        title={
          "✔️Scorio Lights is definitely my go-to shop for all my lighting needs."
        }
        description={
          "Scorio Lights are the best quality and affordable fancy lights wholesale and retail Provider in all over Kerala."
        }
        ogImage={company?.logo[0]?.url}
        canonicalLink="https://scorio.in/"
      />

      <Layout parent="Home" sub="Pages" subChild="">
        <section className="mt-50 mb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12">
                <div className="loop-grid pr-30">
                  <HomePagesGrid show={10} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default AllPages
