import { useWindowSize } from "react-use"

function useWindow() {
  const { width } = useWindowSize()
  const isMobile = width < 768 ? true : false
  const isMobileXs = width < 480 ? true : false

  return {
    isMobile: isMobile,
    isMobileXs: isMobileXs,
  }
}

export default useWindow
