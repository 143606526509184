import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import banner9 from "../../assets/imgs/banner/banner-9.jpg"

import location from "../../assets/imgs/theme/icons/icon-location.svg"
import contact from "../../assets/imgs/theme/icons/icon-contact.svg"
import email from "../../assets/imgs/theme/icons/icon-email-2.svg"
import appStore from "../../assets/imgs/theme/app-store.jpg"
import playStore from "../../assets/imgs/theme/google-play.jpg"
import store1 from "assets/imgs/stores/store1.jpg"
import store2 from "assets/imgs/stores/store2.jpg"
import store3 from "assets/imgs/stores/store3.jpg"
import store4 from "assets/imgs/stores/store4.jpg"

import { useDispatch, useSelector } from "react-redux"
import { getCollections } from "store/actions"
import { map } from "lodash"
import CheckboxList from "components/Common/loaders/CheckboxList"
import useLangSwitch from "constants/LanguageSwicth"
import { year } from "constants/layout"
import { useWindowSize } from "react-use"
import { MainLogo, LogoRed } from "assets/imgs/logos"
import useCloudinary from "constants/UseCloudinaryImage"
import { local_token } from "helpers/api_methods"
import { Card, Col, Row } from "reactstrap"
import { IoLocationSharp } from "react-icons/io5"

const Footer = () => {
  const dispatch = useDispatch()

  const token = localStorage.getItem(local_token)

  const {
    collections,
    loading,
    company,
    socialLinks,
    specialMessages,
    loadingProd,
  } = useSelector(state => ({
    collections: state.LayoutGroup.collections,
    loading: state.LayoutGroup.loading,
    company: state?.SiteSettings.siteSettings?.company,
    socialLinks: state.SiteSettings.siteSettings.socialLinks,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    loadingProd: state.Products.loading,
  }))

  const { width } = useWindowSize()

  useEffect(() => {
    dispatch(getCollections())
  }, [])

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )
  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const stores = [
    {
      img: store1,
      description: "Calicut Rd, Patterkulam",
      place: "Manjeri",
      link: "https://www.google.com/maps/place/Scorio+Lights+%7C+Light+Shop+manjeri+%7C+%7C+All+Lighting+Solution/@11.1203988,76.1086542,15z/data=!4m6!3m5!1s0x3ba6372154a857fd:0xf11006dc17163486!8m2!3d11.1203988!4d76.1086542!16s%2Fg%2F11qpm63jx1?entry=ttu",
    },
    {
      img: store2,
      description: "Calicut Rd, Patterkulam",
      place: "Manjeri",
      link: "https://www.google.com/maps/place/Scorio+Lights+%7C+Light+Shop+manjeri+%7C+%7C+All+Lighting+Solution/@11.1203988,76.1086542,15z/data=!4m6!3m5!1s0x3ba6372154a857fd:0xf11006dc17163486!8m2!3d11.1203988!4d76.1086542!16s%2Fg%2F11qpm63jx1?entry=ttu",
    },
    {
      img: store3,
      description: "Lighting store in Peruvallur",
      place: "Mollappadi",
      link: "https://www.google.com/maps/place/Scorio+Lights+%7C+Light+Shop+Mollapadi,+Karuvankallu/@11.1067356,75.9559409,15z/data=!4m6!3m5!1s0x3ba64f335f92ea7d:0x15eabe75ad06854f!8m2!3d11.1067356!4d75.9559409!16s%2Fg%2F11r9w983lr?entry=ttu",
    },
    {
      img: store4,
      description: "Build Hub, 16th Mile, Thonnakkal",
      place: "Trivandrum",
      link: "https://www.google.com/maps/place/Scorio+Lights+(+Lights+Shop+Trivandrum+%7C+All+Lighting+Solution+)/@8.6445296,76.8439523,15.5z/data=!4m14!1m7!3m6!1s0x3b05c1119ab2bf15:0xe2937532ad2e4727!2sScorio+Lights+(+Lights+Shop+Trivandrum+%7C+All+Lighting+Solution+)!8m2!3d8.6440931!4d76.8436044!16s%2Fg%2F11slhkgdc8!3m5!1s0x3b05c1119ab2bf15:0xe2937532ad2e4727!8m2!3d8.6440931!4d76.8436044!16s%2Fg%2F11slhkgdc8?entry=ttu",
    },
  ]

  return (
    <>
      <footer className="main footer_main">
        {/* {!loadingProd && (
          <section className="newsletter mb-15  wow animate__animated animate__fadeIn pt-4">
            <div className="container">
              <div className="row ">
                <div className="col-lg-12">
                  <div className="position-relative newsletter-inner">
                    <img src={banner9} alt="newsletter" />
                    <div className="newsletter-content">
                    <h2 className="mb-20">
                        Stay home & get your daily <br />
                        needs from our shop
                      </h2>
                      <p className="mb-45">
                        Start you&apos;r daily shopping with{" "}
                        <span className="text-brand">{company?.name}</span>
                      </p>
                    <form className="form-subcriber d-flex">
                      <input type="email" placeholder="Your emaill address" />
                      <button className="btn" type="submit">
                        Subscribe
                      </button>
                    </form>
                    </div>
                    <img src={banner9} alt="newsletter" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )} */}
        {/* <section>
          <div className="container">
            <h3 className="text-center fontPlayfair">Our Stores</h3>
            <div className="mt-4">
              <Row>
                {stores?.map((item, key) => (
                  <Col xs="12" md="3" key={key}>
                    <Card
                      className="mb-3"
                      style={{
                        position: "relative",
                        border: "2px solid #ececec",
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        <a href={item?.link}>
                          <img
                            src={item?.img}
                            alt=""
                            className="store-image pt-2 px-2"
                          />
                        </a>
                      </div>
                      <IoLocationSharp
                        size={30}
                        style={{
                          position: "absolute",
                          bottom: 45,
                          left: -16,
                          color: "red",
                        }}
                      />
                      <p className="text-black px-2 pb-2">
                        {item?.description}
                        <h4 className="text-black"> {item?.place}</h4>
                      </p>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section> */}
        {width >= 768 && (
          <>
            {/* <section className="featured  section-padding">
              <div className="container">
                <div className="row ">
                  <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
                    <div
                      className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                      data-wow-delay="0"
                    >
                      <div className="banner-icon">
                        <img src={icon1} alt="" />
                      </div>
                      <div className="banner-text">
                        <h6 className="icon-box-title">Best prices & offers</h6>
                        <p>Orders more</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                    <div
                      className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="banner-icon">
                        <img src={icon2} alt="icon2" />
                      </div>
                      <div className="banner-text">
                        <h6 className="icon-box-title">Free delivery</h6>
                        <p>24/7 amazing services</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                    <div
                      className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="banner-icon">
                        <img src={icon3} alt="" />
                      </div>
                      <div className="banner-text">
                        <h6 className="icon-box-title">Great daily deal</h6>
                        <p>
                          When you sign up
                          <br />
                          &nbsp;
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                    <div
                      className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <div className="banner-icon">
                        <img src={icon4} alt="" />
                      </div>
                      <div className="banner-text">
                        <h6 className="icon-box-title">Wide assortment</h6>
                        <p>Mega Discounts</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                    <div
                      className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                      data-wow-delay=".4s"
                    >
                      <div className="banner-icon">
                        <img src={icon5} alt="" />
                      </div>
                      <div className="banner-text">
                        <h6 className="icon-box-title">Easy returns</h6>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 d-xl-none">
                <div
                  className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                >
                  <div className="banner-icon">
                    <img src={icon6} alt="" />
                  </div>
                  <div className="banner-text">
                    <h3 className="icon-box-title">Safe delivery</h3>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </section> */}

            <section className="section-padding footer-mid">
              <div className="container pt-15 pb-20 sm:p-3 ">
                <div className="custom_footer">
                  <div className="row ">
                    <div className="col">
                      <div
                        className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0  wow animate__animated animate__fadeInUp"
                        data-wow-delay="0"
                      >
                        <div className="logo">
                          <Link to="/">
                            <span className="mb-15">
                              <img
                                src={LogoRed}
                                alt="logo"
                                height="40px"
                                width={"100px"}
                                className="fit-contain footer-logo"
                              />
                            </span>
                          </Link>
                        </div>
                        <ul className="contact-infor">
                          {company?.address?.addressLine1 && (
                            <li>
                              {" "}
                              <img src={location} alt="" />
                              <strong>Address: </strong>{" "}
                              <span className="">
                                {company?.address?.addressLine1}
                              </span>
                            </li>
                          )}
                          {company?.contactDetails?.mobile && (
                            <li>
                              <img src={contact} alt="" />
                              <strong>Call Us : </strong>
                              <span>
                                &nbsp;{company?.contactDetails?.mobile}
                              </span>
                            </li>
                          )}
                          {company?.contactDetails?.email && (
                            <li>
                              <img src={email} alt="" />
                              <strong>Email : </strong>
                              <span>
                                &nbsp;{company?.contactDetails?.email}
                              </span>
                            </li>
                          )}
                          {/* {company?.contactDetails?.website && (
                      <li>
                        <img src={email} alt="" />
                        <strong>website : </strong>
                        <span>&nbsp;{company?.contactDetails?.website}</span>
                      </li>
                    )} */}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <h5 className="widget-title">Company</h5>
                      <ul className="footer-list  mb-sm-5 mb-md-0">
                        {/* <li>
                    <Link to="/about">About Us</Link>
                  </li> */}
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/terms">Terms</Link>
                        </li>
                        <li>
                          <Link to="/return-policy">Return Policy</Link>
                        </li>
                        <li>
                          <Link to="/pages">Pages</Link>
                        </li>
                        {/* <li>
                          <Link to="/contact">Contact Us</Link>
                        </li> */}
                      </ul>
                    </div>
                    <div
                      className="footer-link-widget col col-xs-6  wow animate__animated animate__fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <h5 className="widget-title ">Account</h5>
                      <ul className="footer-list  mb-sm-5 mb-md-0">
                        <li>
                          {token ? (
                            <Link
                              to={{
                                pathname: "/user-account",
                                state: { index: 1 },
                              }}
                            >
                              Account
                            </Link>
                          ) : (
                            <Link to="#!" onClick={handleLoginPopup}>
                              Sign In
                            </Link>
                          )}
                        </li>
                        <li>
                          <Link to="/cart">View Cart</Link>
                        </li>
                        <li>
                          <Link to="/wishlist">My wish list</Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: "/user-account",
                              state: { index: 2 },
                            }}
                          >
                            My Order
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="footer-link-widget col col-xs-6   wow animate__animated animate__fadeInUp"
                      data-wow-delay=".4s"
                    >
                      {collections?.lenght >= 1 ? (
                        <>
                          <h5 className="widget-title ">Popular Collection</h5>
                          <ul className="footer-list mb-sm-5 mb-md-0">
                            {loading ? (
                              <CheckboxList />
                            ) : (
                              <>
                                {map(collections?.slice(0, 4), (coll, key) => (
                                  <li key={key}>
                                    <Link
                                      to={`/collections/${coll?.primaryLang?.title?.replace(
                                        / /g,
                                        "-"
                                      )}`}
                                    >
                                      {coll?.primaryLang?.title}
                                    </Link>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* <div className="col display-md-block"></div> */}
                    {/* 
                    {width > 768 && (
                      <div
                        className="footer-link-widget widget-install-app col   wow animate__animated animate__fadeInUp w-100 install_app_container"
                        data-wow-delay=".5s"
                      >
                        <h5 className="widget-title ">Install App</h5>
                        <p className="">From App Store or Google Play</p>
                        <div className="download-app">
                          <a href="#" className="hover-up mb-sm-2 mb-lg-0">
                            <img className="active" src={appStore} alt="" />
                          </a>
                          <a href="#" className="hover-up mb-sm-2">
                            <img src={playStore} alt="" />
                          </a>
                        </div>
                      </div>
                    )} */}
                  </div>

                  {/* {width < 768 && (
                    <div
                      className="footer-link-widget widget-install-app col   wow animate__animated animate__fadeInUp w-100 install_app_container"
                      data-wow-delay=".5s"
                    >
                      <h4 className="widget-title ">Install App</h4>
                      <p className="text-center">
                        From App Store or Google Play
                      </p>
                      <div className="download-app align-items-center justify-content-center">
                        <a href="#" className="hover-up mb-sm-2 mb-lg-0">
                          <img className="active" src={appStore} alt="" />
                        </a>
                        <a href="#" className="hover-up mb-sm-2">
                          <img src={playStore} alt="" />
                        </a>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </section>
            <div
              className="container pb-30  wow animate__animated animate__fadeInUp"
              data-wow-delay="0"
            >
              <div className="row align-items-center">
                <div className="col-12 mb-30">
                  <div className="footer-bottom"></div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6">
                  <p className="font-sm mb-0">
                    &copy; {year}{" "}
                    <span className="text-osperb">{company?.name}</span>
                  </p>
                </div>
                <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
                  {/* <div className="hotline d-lg-inline-flex">
                    <img src={phone} alt="hotline" />
                    <p>
                      {company?.contactDetails?.mobile}
                      <span>24/7 Support Center</span>
                    </p>
                  </div> */}
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                  <span className="d-flex justify-content-end">
                    Designed by&nbsp;
                    <a
                      href="https://osperb.com"
                      title="website development firm"
                    >
                      <span to="/">Osperb</span>
                      <br />
                    </a>
                    &nbsp; All rights reserved
                  </span>
                  {/* <div className="mobile-social-icon">
                    {socialLinks?.facebook && (
                      <a
                        href={socialLinks?.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={facebook} alt="" />
                      </a>
                    )}
                    {socialLinks?.instagram && (
                      <a
                        href={socialLinks?.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={instagram} alt="" />
                      </a>
                    )}

                    {socialLinks?.linkedin && (
                      <a
                        href={socialLinks?.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <FaLinkedinIn color="white" />
                      </a>
                    )}

                    {socialLinks?.whatsApp && (
                      <a
                        href={socialLinks?.whatsApp}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <FaWhatsapp color="white" />
                      </a>
                    )}
                    {socialLinks?.snapchat && (
                      <a
                        href={socialLinks?.snapchat}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <RiSnapchatLine color="white" />
                      </a>
                    )}
                    {socialLinks?.tiktok && (
                      <a
                        href={socialLinks?.tiktok}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <FaTiktok color="white" />
                      </a>
                    )}
                  </div>
                  {language?.productOffer && (
                    <p className="font-sm">{language?.productOffer}</p>
                  )} */}
                </div>
              </div>
            </div>
          </>
        )}
      </footer>
    </>
  )
}

export default Footer
