import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_BANNERS } from "./actionTypes"
import { getBannersSuccess, getBannersFail } from "./actions"

import { get } from "helpers/api_methods"

function getBannerApi() {
  return get("/banner/user/all")
}

function* fetchBanner({ payload }) {
  try {
    const response = yield call(getBannerApi, payload)
    yield put(getBannersSuccess(response))
  } catch (error) {
    yield put(getBannersFail(error))
  }
}

function* bannerSaga() {
  yield takeEvery(GET_BANNERS, fetchBanner)
}

export default bannerSaga
