import React from "react"
import PropTypes from "prop-types"
import Rating from "react-rating"
import { FaRegStar, FaStar } from "react-icons/fa"

function MyRating(props) {
  console.log(props)
  return (
    <Rating
      emptySymbol={<FaRegStar />}
      fullSymbol={<FaStar />}
      className="text-warning"
      {...props}
      quiet={true}
      readonly={true}
    />
  )
}

export default MyRating

MyRating.propTypes = {
  props: PropTypes.any,
}
