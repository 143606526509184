import React from "react"
import PropTypes from "prop-types"

function DashLine({ color }) {
  return (
    <div className="dot_wrapper">
      <svg
        version="1.1"
        id="line_2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        xmlSpace="preserve"
      >
        <path
          className="path-vert"
          fill={color}
          strokeWidth="3"
          stroke={color}
          d="M30 0 v600 400"
        />
      </svg>
    </div>
  )
}

export default DashLine

DashLine.propTypes = {
  color: PropTypes.string,
}
