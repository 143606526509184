import { FRONT_END_URL } from "helpers/api_methods"
import React from "react"
import whatsapp from "assets/imgs/other/Whatsapp2.svg"
import { useLocation } from "react-router-dom"

function Chat() {
  const location = useLocation()
  return (
    <a
      href={`whatsapp://send?phone=+918145000222&text=${FRONT_END_URL}${location.pathname}. %0aHi,%20I%20would%20like%20to%20know%20more%20about%20this%20product 🤩.`}
      className={`fixed_btn scroll-to-top show  blink`}
    >
      <div id="chat-btn" className="w_button">
        <img
          src={whatsapp}
          title="scorio-whatsapp"
          loading="eager"
          alt="whatsapp"
        />
      </div>
    </a>
  )
}

export default Chat
