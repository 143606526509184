import React, { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import iconHeart from "assets/imgs/theme/icons/icon-heart.png"
import iconHeartBlack from "assets/imgs/theme/icons/black/icon-heart.png"
import iconCart from "assets/imgs/theme/icons/icon-cart.png"
import iconCartBlack from "assets/imgs/theme/icons/black/icon-cart.png"
import iconUser from "assets/imgs/theme/icons/icon-user.png"
import { LogoRed } from "assets/imgs/logos"
import iconSearchBlack from "assets/imgs/theme/icons/black/fi-rs-search.png"

import Search from "components/ecommerce/search/Search"

import {
  getCategories,
  getCollections,
  getFavouriteItems,
  getLocalCart,
  getQuicklist,
  getUserCart,
  logoutUser,
  showLoginPopupAction,
} from "store/actions"
import { map } from "lodash"
import CartItemList from "components/elements/cartItemComp2"
import usePrice from "constants/UsePrice"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "constants/LanguageSwicth"
import { useOutsideAlerter } from "constants/useOutSideClick"
import { useWindowSize } from "react-use"
import { MainLogo } from "assets/imgs/logos"
import { useLocalCartPirces } from "constants/useCartAction"
import { local_token } from "helpers/api_methods"
import { FiUserCheck } from "react-icons/fi"

const Header = ({ totalCartItems, toggleClick }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cateRef = useRef(null)
  const location = useLocation()
  const { width } = useWindowSize()

  const token = localStorage.getItem(local_token)

  const {
    cartItems,
    userCart,
    company,
    specialMessages,
    favourites,
    collections,
    localCart,
    user,
  } = useSelector(state => ({
    user: state.Login.user,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    company: state?.SiteSettings.siteSettings?.company,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    favourites: state.Cart.favourites,
    // quickPurchase: state.Cart.quickPurchase,
    collections: state.LayoutGroup.collections,
  }))

  const [isToggled, setToggled] = useState(false)
  const [scroll, setScroll] = useState(false)

  const [scrollY, setScrollY] = useState()

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(cateRef, setOutSide)

  useEffect(() => {
    const onScroll = e => {
      setScrollY(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    if (scrollY >= 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollY])

  const handleToggle = () => setToggled(!isToggled)

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getCategories("", "all"))
    dispatch(getFavouriteItems(1, 10))
    dispatch(getQuicklist(1, 10))
  }, [dispatch])

  useEffect(() => {
    dispatch(getCollections())
  }, [dispatch])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleLoginFun = path => {
    if (path) {
      history.push(path)
    }
  }

  useEffect(() => {
    if (outSide === true) {
      setToggled(false)
      setOutSide(false)
    }
  }, [outSide])

  const isHotDeals = collections?.filter(i =>
    i?.primaryLang?.title === "Hot Deals" ? true : false
  )

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  const { itemTotal } = useLocalCartPirces()

  return (
    <>
      <header
        className={`${
          width <= 480 && location.pathname === "/" ? "header-area" : ""
        }  header-style-1 header-height-2`}
      >
        {language?.productOffer && (
          <div className="mobile-promotion">
            {language?.productOffer && <span>{language?.productOffer}</span>}
          </div>
        )}
        {width > 480 ? (
          <div
            className={`${
              location.pathname === "/" ? "scorio-header-middle" : ""
            }  header-middle header-middle-ptb-1 d-lg-block`}
          >
            <div className="container">
              <div className="header-wrap">
                <div className="header-right mx-4">
                  <div className="logo logo-width-2">
                    <Link to="/">
                      <img
                        src={
                          // company?.logo[0]?.url
                          // ? useCloudinary(company?.logo[0]?.url, "w_300")
                          // :
                          MainLogo
                        }
                        height="60px"
                        width={"120px"}
                        alt="logo"
                        className="fit-contain mx-4 custom_logo_img"
                      />
                    </Link>
                  </div>
                  <div className="search-style-2 position-relative max-w:600px d-flex align-items-center">
                    <Search />
                    <Link className="w-25 mx-3" to={"/shop-list"}>
                      <span className="lable text-white">All Products</span>
                    </Link>
                  </div>
                  <div className="header-action-right">
                    <div className="header-action-2">
                      <div className="header-action-icon-2 ">
                        <Link
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                        >
                          <span>
                            <img
                              className="svgInject"
                              alt="scorio"
                              src={iconHeart}
                            />
                            {favourites?.total >= 1 ? (
                              <span
                                className={`pro-count ${
                                  location.pathname === "/" ? "blue" : "red"
                                }`}
                              >
                                {favourites?.total}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Link>
                        <Link
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                        >
                          <span className="lable">Wish List</span>
                        </Link>
                      </div>

                      <div className="header-action-icon-2">
                        <Link to={"#!"} onClick={() => handleLoginFun("/cart")}>
                          <div className="mini-cart-icon">
                            <img alt="scorio" src={iconCart} />
                            {handleCartItem()?.length >= 1 ? (
                              <span
                                className={`pro-count ${
                                  location.pathname === "/" ? "blue" : "red"
                                }`}
                              >
                                {handleCartItem()?.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Link>

                        <Link to={"#!"} onClick={() => handleLoginFun("/cart")}>
                          <span className="lable">Cart</span>
                        </Link>

                        <div className="cart-dropdown-wrap cart-dropdown-hm2">
                          {handleCartItem()?.length >= 1 ? (
                            <>
                              <ul>
                                {map(handleCartItem(), (item, key) => (
                                  <Fragment key={key}>
                                    <CartItemList item={item} />
                                  </Fragment>
                                ))}
                              </ul>
                              <div className="shopping-cart-footer">
                                <div className="shopping-cart-total">
                                  <h4>
                                    Total
                                    <span className="secondary-color">
                                      {currencyCode}{" "}
                                      {userCart?.totalPrice
                                        ? userCart?.totalPrice
                                        : itemTotal}
                                    </span>
                                  </h4>
                                </div>
                                <div className="shopping-cart-button">
                                  <Link
                                    onClick={() => handleLoginFun("/cart")}
                                    to={"#!"}
                                  >
                                    View cart
                                  </Link>
                                </div>
                              </div>
                            </>
                          ) : (
                            <EmptyCartSvg style={{ height: "170px" }} />
                          )}
                        </div>
                      </div>

                      <div className="header-action-icon-2">
                        {token ? (
                          <>
                            <Link to="/user-account">
                              <span>
                                <img
                                  className="svgInject mx-1"
                                  alt="user"
                                  src={iconUser}
                                />
                              </span>
                            </Link>
                            <Link to="/user-account">
                              <span>
                                {user?.name ? (
                                  <span style={{ textTransform: "capitalize" }}>
                                    {user?.name?.split(" ")[0]}
                                  </span>
                                ) : (
                                  <span className="lable ml-0">Account</span>
                                )}
                              </span>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to="#!" onClick={handleLoginPopup}>
                              <span>
                                <img
                                  className="svgInject mx-1"
                                  alt="user"
                                  src={iconUser}
                                />
                              </span>
                            </Link>
                            <Link to="#!" onClick={handleLoginPopup}>
                              <span>
                                <span className="lable ml-0">Login</span>
                              </span>
                            </Link>
                          </>
                        )}
                        {token ? (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              <li>
                                <Link to="/user-account">
                                  <span>
                                    <i className="fi fi-rs-user mr-10"></i>
                                    My Account
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/user-account",
                                    state: { index: 2 },
                                  }}
                                >
                                  <span>
                                    <i className="fi fi-rs-location-alt mr-10"></i>
                                    Order Tracking
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: "/user-account",
                                    state: { index: 4 },
                                  }}
                                >
                                  <span>
                                    <i className="fi fi-rs-label mr-10"></i>
                                    My Address
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"#!"}
                                  onClick={() => handleLoginFun("/wishlist")}
                                >
                                  <span>
                                    <i className="fi fi-rs-heart mr-10"></i>
                                    My Wish List
                                  </span>
                                </Link>
                              </li>

                              <li>
                                <Link to="#!" onClick={handleLogout}>
                                  <span>
                                    <i className="fi fi-rs-sign-out mr-10"></i>
                                    Sign out
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              scroll &&
              (location.pathname === "/shop-list" ||
                location.pathname === "/categroies" ||
                location.pathname === "/collections")
                ? "header-bottom header-bottom-bg-color sticky-bar stick"
                : "header-bottom header-bottom-bg-color sticky-bar"
            }
          >
            {scroll === true &&
            (location.pathname === "/shop-list" ||
              location.pathname === "/categroies" ||
              location.pathname === "/collections") ? (
              <div
                className={`search-style-2 position-relative max-w:600px myMobileSearch ${
                  scroll === true ? "scroll" : ""
                }`}
              >
                <Search isMobile={true} />
              </div>
            ) : (
              <>
                <div className="container">
                  <div className="header-wrap header-space-between position-relative">
                    <div
                      className="logo logo-width d-block d-lg-none d-flex align-items-center"
                      style={{
                        padding: width > 768 && "15px 0",
                        width: "100px",
                      }}
                    >
                      <div className="header-action-icon d-block d-lg-none ">
                        <div
                          className="burger-icon burger-icon-white"
                          onClick={toggleClick}
                        >
                          <span className="burger-icon-top"></span>
                          <span className="burger-icon-mid"></span>
                          <span className="burger-icon-bottom"></span>
                        </div>
                      </div>
                      <Link
                        to="/"
                        className="mx-1"
                        style={{ height: "20px", width: "80px" }}
                      >
                        <img
                          src={company?.logo[0]?.url ?? MainLogo}
                          alt="logo"
                          height="100%"
                          width={"100%"}
                          className="fit-contain"
                        />
                      </Link>
                    </div>
                    {/* logo center */}
                    <div
                      style={{ width: "100px" }}
                      onClick={() => history.push("/")}
                    >
                      <img
                        src={LogoRed}
                        alt="logo"
                        height="30px"
                        width={"100px"}
                        className="fit-contain"
                      />
                    </div>
                    {width > 992 && scroll === true && (
                      <div className="header-action-icon-2 d-flex align-items-center">
                        <Link
                          className="position-relative"
                          to={"#!"}
                          onClick={() => handleLoginFun("/wishlist")}
                        >
                          <span>
                            <img
                              className="svgInject"
                              alt="scorio"
                              src={iconHeart}
                            />
                          </span>
                        </Link>
                        <Link
                          to={"#!"}
                          className="position-relative mx-2"
                          onClick={() => handleLoginFun("/cart")}
                        >
                          <div className="mini-cart-icon">
                            <img alt="scorio" src={iconCart} />
                            {handleCartItem()?.length >= 1 ? (
                              <span
                                className="pro-count text-white navIcon2 bg-darkGreen px-1"
                                style={{ borderRadius: "50%" }}
                              >
                                {handleCartItem()?.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Link>
                        {token && (
                          <Link to="/user-account">
                            <span>
                              <img
                                className="svgInject"
                                alt="user"
                                src={iconUser}
                              />
                            </span>
                          </Link>
                        )}
                      </div>
                    )}

                    <div
                      className="header-action-right d-block d-lg-none"
                      style={{ width: "100px" }}
                    >
                      <div className="header-action-2">
                        <Link
                          to="/user-account"
                          className="d-flex align-items-center justify-content-center"
                        >
                          {user?.name ? (
                            <span>
                              <span style={{ textTransform: "capitalize" }}>
                                {user?.name?.split(" ")[0]}
                              </span>
                            </span>
                          ) : //  (
                          //   <FiUserCheck
                          //     className="primary-color"
                          //     size={"20px"}
                          //   />
                          // )
                          null}
                        </Link>
                        {!token ? (
                          <div className="header-action-icon-2">
                            <Link
                              className="m-0 d-flex align-items-center justify-content-center"
                              to="#!"
                              onClick={handleLoginPopup}
                            >
                              <span className="primary-color sm:color-black">
                                Login
                              </span>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="header-action-icon-2">
                          <Link
                            to={"#!"}
                            onClick={() => handleLoginFun("/wishlist")}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <span className="mini-cart-icon">
                              <img alt="scorio" src={iconHeartBlack} />
                              {favourites?.total >= 1 && (
                                <span className="pro-count white">
                                  {favourites?.total}
                                </span>
                              )}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`search-style-2 mobile-style-3 position-relative max-w:600px myMobileSearch`}
                >
                  <Search isMobile={true} />
                </div>
              </>
            )}
          </div>
        )}
      </header>
    </>
  )
}

export default Header

Header.propTypes = {
  totalCartItems: PropTypes.any,
  toggleClick: PropTypes.any,
  totalWishlistItems: PropTypes.any,
}
