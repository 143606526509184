import React from "react"
import "react-loading-skeleton/dist/skeleton.css"
import { useWindowSize } from "react-use"
import PropTypes from "prop-types"
import ContentLoader from "react-content-loader"

function ProductLoader({ height, innerClass }) {
  const { width } = useWindowSize()

  return (
    <>
      {width >= 768 ? (
        <ContentLoader
          viewBox="0 0 800 400"
          height={900}
          className="mt-4"
          width={"100%"}
        >
          <circle cx="472" cy="159" r="7" />
          <rect x="487" y="154" rx="5" ry="5" width="220" height="10" />
          <circle cx="472" cy="190" r="7" />
          <rect x="487" y="184" rx="5" ry="5" width="220" height="10" />
          <circle cx="472" cy="219" r="7" />
          <rect x="487" y="214" rx="5" ry="5" width="220" height="10" />
          <circle cx="472" cy="249" r="7" />
          <rect x="487" y="244" rx="5" ry="5" width="220" height="10" />
          <rect x="64" y="18" rx="0" ry="0" width="346" height="300" />
          <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
          <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
          <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
          <rect x="10" y="20" rx="0" ry="0" width="40" height="44" />
          <rect x="10" y="80" rx="0" ry="0" width="40" height="44" />
          <rect x="10" y="140" rx="0" ry="0" width="40" height="44" />
          <rect x="194" y="329" rx="0" ry="0" width="0" height="0" />
          <rect x="192" y="323" rx="0" ry="0" width="0" height="0" />
          <rect x="185" y="323" rx="0" ry="0" width="0" height="0" />
          <rect x="10" y="200" rx="0" ry="0" width="40" height="44" />
          <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
          <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
          <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
          <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
          <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
          <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
          <rect x="470" y="99" rx="0" ry="0" width="70" height="30" />
          <rect x="560" y="99" rx="0" ry="0" width="70" height="30" />
        </ContentLoader>
      ) : (
        <ContentLoader
          viewBox="0 0 400 475"
          height={575}
          className="mt-4"
          width={"100%"}
        >
          <circle cx="30" cy="258" r="30" />
          <rect x="75" y="233" rx="4" ry="4" width="100" height="13" />
          <rect x="75" y="260" rx="4" ry="4" width="50" height="8" />
          <rect x="0" y="210" rx="5" ry="5" width="400" height="10" />
          <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
        </ContentLoader>
      )}
    </>
  )
}

export default ProductLoader

ProductLoader.propTypes = {
  height: PropTypes.string,
  innerClass: PropTypes.string,
}
