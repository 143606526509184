import { call, put, takeEvery } from "redux-saga/effects"

import { get } from "helpers/api_methods"
import {
  getBlogDetailsFail,
  getBlogDetailsSuccess,
  getBlogsFail,
  getBlogsSuccess,
} from "./actions"
import { GET_BLOGS, GET_BLOG_DETAILS } from "./actionTypes"

function fetchAllBlogApi({ sort, page }) {
  return get(`/blog/user/all?page=${page ? page : 1}&sort=${sort}`)
}

function* fetchBlogs({ payload }) {
  try {
    const response = yield call(fetchAllBlogApi, payload)
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getBlogsFail(error))
  }
}

const getBlogDetailsAPi = blogId => {
  return get(`/blog/user/${blogId}`)
}

function* fetchBlogDetails({ payload: blogId }) {
  try {
    const response = yield call(getBlogDetailsAPi, blogId)
    yield put(getBlogDetailsSuccess(response))
  } catch (error) {
    yield put(getBlogDetailsFail(error))
  }
}

function* blogSaga() {
  yield takeEvery(GET_BLOGS, fetchBlogs)
  yield takeEvery(GET_BLOG_DETAILS, fetchBlogDetails)
}

export default blogSaga
