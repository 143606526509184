import React, { useState } from "react"
import PropTypes from "prop-types"
import usePrice from "constants/UsePrice"
import { Link } from "react-router-dom"
import useLangSwitch from "constants/LanguageSwicth"
import { useDispatch, useSelector } from "react-redux"
import useCartActions from "constants/useCartAction"
import { addOrRemoveFav, getLocalCart } from "store/actions"
import { FaSpinner } from "react-icons/fa"
import useCloudinary from "constants/UseCloudinaryImage"
import { local_token } from "helpers/api_methods"

function WishListInnerItem({ item, isQuickList }) {
  const dispatch = useDispatch()
  const product = item?.product
  const { currencyCode, oldPrice, price, stock, currentVarient } =
    usePrice(product)
  const { loading } = useSelector(state => ({
    loading: state.Cart.addOrRemoveLoad,
  }))
  const token = localStorage.getItem(local_token)

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)
  const [qty, setQty] = useState({ id: "", qty: 1 })

  const handleAddCart = product => {
    const options = {
      product: product?._id,
      varient: product?.varients[0]?._id,
      quantity: qty.id === product?._id ? qty.qty : 1,
      operation: "add",
      isNoti: true,
    }
    useCartActions(options, dispatch, product)
    if (!token) {
      dispatch(getLocalCart())
    }
  }

  const deleteFromWishlist = productId => {
    dispatch(addOrRemoveFav(productId))
  }

  const increaseQuantity = item => {
    setQty({ id: item?.product?._id, qty: qty?.qty + 1 })
  }

  const decreaseQuantity = item => {
    if (qty?.qty <= 1) {
      return
    } else {
      setQty({ id: item?.product?._id, qty: qty?.qty - 1 })
    }
  }

  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <>
      <tr className="pt-30 border-none border-b-4">
        <td className="image product-thumbnail pt-40 d-flex align-items-center">
          <img
            src={useCloudinary(product.images[0]?.url, "h_400,w_400")}
            alt=""
            className="img-fluid"
          />
          <h6 className="product-name  mb-10 mx-2">
            <Link to={`/products/${removeSpace}`}>
              {language?.name}{" "}
              {item?.varientValue
                ? `(${item?.varientValue} ${item?.varientUnit})`
                : `(${currentVarient})`}
            </Link>
          </h6>
        </td>

        <td className="price" data-title="Price">
          <h3 className="text-brand">
            <del className="old-price text-gray font-md ml-15">{oldPrice}</del>
            &nbsp;
            {currencyCode} {price}
          </h3>
        </td>

        {isQuickList && (
          <td
            className="text-start detail-info sm:display-between"
            data-title="Quantity"
          >
            <div className="detail-extralink ">
              <div className="detail-qty border radius m-0 ">
                <a onClick={() => decreaseQuantity(item)} className="qty-down">
                  <i className="fi-rs-angle-small-down"></i>
                </a>
                <span className="qty-val d-flex align-items-center">
                  {qty?.id === item?.product?._id ? qty?.qty : 1}
                  {loading && <FaSpinner className="mx-2 spinner" />}
                </span>
                <a onClick={() => increaseQuantity(item)} className="qty-up">
                  <i className="fi-rs-angle-small-up"></i>
                </a>
              </div>
            </div>
          </td>
        )}

        <td className="text-right" data-title="Cart">
          {stock === 0 ? (
            <div className="detail-info" data-title="Stock">
              <span className="stock-status out-stock mb-0">Out of stock</span>
            </div>
          ) : (
            <button
              className={"btn btn-sm "}
              onClick={e => handleAddCart(product)}
            >
              Add to cart
            </button>
          )}
        </td>
        {!isQuickList && (
          <td className="action" data-title="Remove">
            <a onClick={e => deleteFromWishlist(product?._id)}>
              <i className="fi-rs-trash"></i>
            </a>
          </td>
        )}
      </tr>
    </>
  )
}

export default WishListInnerItem

WishListInnerItem.propTypes = {
  item: PropTypes.any,
  isQuickList: PropTypes.bool,
}
