import FeatchDeals from "components/ecommerce/fetchDeals"
import React from "react"
import { useBanner } from "./useBannerData"
import PropTypes from "prop-types"

function CollectionG2({ groupDetail, col, sm }) {
  const banner = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <>
      <section className="section-padding pb-5 sm:mt-5">
        <div className="container">
          <div
            className="section-title flex-column align-items-center  wow animate__animated animate__fadeIn"
            data-wow-delay="0"
          >
            <h3 className="text-center fontPlayfair">
              {groupDetail?.primaryLang?.title}
            </h3>
              {!sm <= 420 ? 
          ""
            :
            <p className="text-center">
            {groupDetail?.primaryLang?.description}
          </p>
          }
            {/* {groupDetail?.type === "collections" ? (
              <Link to={`/collections`}>
                <span className="show-all">
                  Explore
                  <i className="fi-rs-angle-right"></i>
                </span>
              </Link>
            ) : (
              <Link to={`/categroies`}>
                <span className="show-all">
                  Explore
                  <i className="fi-rs-angle-right"></i>
                </span>
              </Link>
            )} */}
          </div>
          <FeatchDeals listData={banner} col={col} sm={sm} />
        </div>
      </section>
    </>
  )
}

export default CollectionG2

CollectionG2.propTypes = {
  groupDetail: PropTypes.object,
  col: PropTypes.number,
  sm: PropTypes.bool,
}
