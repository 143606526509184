import {
  GET_BANNERS,
  GET_BANNERS_FAIL,
  GET_BANNERS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  banners: [],
  error: {},
  loading: false,
}

const Banner = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return {
        ...state,
        loading: true,
      }
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false,
      }

    case GET_BANNERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Banner
