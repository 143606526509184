import {
  GET_BLOGS,
  GET_BLOGS_FAIL,
  GET_BLOGS_SUCCESS,
  GET_BLOG_DETAILS,
  GET_BLOG_DETAILS_FAIL,
  GET_BLOG_DETAILS_SUCCESS,
} from "./actionTypes"

//all blog
export const getBlogs = (sort, page) => ({
  type: GET_BLOGS,
  payload: { sort, page },
})

//all blog
export const getBlogsSuccess = res => ({
  type: GET_BLOGS_SUCCESS,
  payload: res,
})

export const getBlogsFail = error => ({
  type: GET_BLOGS_FAIL,
  payload: error,
})

export const getBlogDetails = blogId => ({
  type: GET_BLOG_DETAILS,
  payload: blogId,
})

export const getBlogDetailsSuccess = blogDetails => ({
  type: GET_BLOG_DETAILS_SUCCESS,
  payload: blogDetails,
})

export const getBlogDetailsFail = error => ({
  type: GET_BLOG_DETAILS_FAIL,
  payload: error,
})
