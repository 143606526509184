import React, { useState } from "react"
import PropTypes from "prop-types"
import { Markup } from "interweave"
const ProductTab = ({ details }) => {
  const [activeIndex, setActiveIndex] = useState(2)

  const handleOnClick = index => {
    setActiveIndex(index)
  }

  return (
    <div className="product-info">
      <div className="tab-style3">
        <ul className="nav nav-tabs text-uppercase">
          <li className="nav-item">
            <a
              className={activeIndex === 1 ? "nav-link active" : "nav-link"}
              id="Description-tab"
              data-bs-toggle="tab"
              onClick={() => handleOnClick(1)}
            >
              Description
            </a>
          </li>{" "}
          <li className="nav-item">
            <a
              className={activeIndex === 2 ? "nav-link active" : "nav-link"}
              id="highlights-tab"
              data-bs-toggle="tab"
              onClick={() => handleOnClick(2)}
            >
              Highlights
            </a>
          </li>
        </ul>
        <div className="tab-content shop_info_tab entry-main-content">
          <div
            className={
              activeIndex === 1 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="Description"
          >
            <div className="">
              <span>
                <Markup content={details?.description} />
              </span>
            </div>
          </div>
          <div
            className={
              activeIndex === 2 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id="Description"
          >
            <div className="">
              <span>
                <Markup content={details?.highlights} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductTab

ProductTab.propTypes = {
  details: PropTypes.object,
}
