import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import cartSaga from "./cart&order/saga"
import productsSaga from "./product/saga"
import layoutGroupSaga from "./group&collections/saga"
import bannerSaga from "./banner/saga"
import classificationSaga from "./classifications/saga"
import siteSettingsSaga from "./settings/saga"
import blogSaga from "./blogs/saga"
import LayoutPagesSaga from "./pages/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(cartSaga),
    fork(productsSaga),
    fork(layoutGroupSaga),
    fork(bannerSaga),
    fork(classificationSaga),
    fork(siteSettingsSaga),
    fork(blogSaga),
    fork(LayoutPagesSaga),
  ])
}
