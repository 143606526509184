import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useLocation } from "react-router-dom"

import { map } from "lodash"
import useCloudinary from "constants/UseCloudinaryImage"
import { useWindowSize } from "react-use"

function CollectionList({ listData, col, isGridItem }) {
  const history = useHistory()
  const { width } = useWindowSize()

  const handleRouteLink = name => {
    if (name?.title) {
      const CollectionLink = name?.title?.replace(/ /g, "-")
      return history.push(`/collections/${CollectionLink}`)
    } else if (name?.name) {
      const CategoryLink = name?.name?.replace(/ /g, "-")
      return history.push({
        pathname: "/shop-list",
        query: {
          cat: CategoryLink,
          isProduct: false,
        },
      })
    } else return
  }

  return (
    <>
      {map(listData?.slice(0, width >= 480 ? 8 : 4), (item, key) => (
        <div
          className={`col-lg-${col ? 3 : 4} ${
            isGridItem && ""
          }  col-md-6 col-sm-12  mb-4 px-5`}
          key={key}
        >
          <div
            className={`${
              !isGridItem && "banner-img"
            } mobileContainer wow animate__animated animate__fadeInUp ${
              isGridItem ? "gridItem " : "max-h:300px w-100"
            }`}
            data-wow-delay="0"
          >
            <span
              onClick={() => handleRouteLink(item?.primaryLang)}
              className="cursor-pointer px-5"
            >
              <img
                src={useCloudinary(item?.bannerImages[0]?.url ?? "", "w_1000")}
                alt=""
                className={`${
                  isGridItem ? "gridItem img" : "h-100 w-100"
                }  fit-cover object-start mobileBanner`}
              />
              <div className={``} style={{ marginLeft: 100, padding: 2 }}>
                <p className="text-black m-0" style={{ lineHeight: "18px" }}>
                  {item?.primaryLang?.name}
                </p>
                <p
                  className="text-muted"
                  style={{ lineHeight: "20px", fontSize: 14 }}
                >
                  {item?.primaryLang?.description}
                </p>
              </div>
            </span>
            <div className="banner-text">
              {/* <h4 className="mb-0">&nbsp;</h4> */}
              {/* <Link to="/products">
             <span className="btn btn-xs text-white">
               Shop Now <i className="fi-rs-arrow-small-right"></i>
             </span>
           </Link> */}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default CollectionList

CollectionList.propTypes = {
  listData: PropTypes.any,
  col: PropTypes.number,
  isGridItem: PropTypes.bool,
}
