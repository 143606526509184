import SliderList2 from "components/elements/SliderList2"
import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import useLangSwitch from "constants/LanguageSwicth"
import { useBanner } from "./useBannerData"
import useCloudinary from "constants/UseCloudinaryImage"
import { useWindowSize } from "react-use"

function ProductG4({ groupDetail }) {
  const history = useHistory()
  const { width } = useWindowSize()
  const language = useLangSwitch(
    groupDetail?.primaryLang,
    groupDetail?.secondaryLang
  )

  const product = groupDetail?.products
  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  const CollectionLink = handleBannerData[0]?.primaryLang?.title?.replace(
    / /g,
    "-"
  )

  const CategoryLink = handleBannerData[0]?.primaryLang?.name?.replace(
    / /g,
    "-"
  )

  const handleRouteLink = () => {
    switch (groupDetail?.type) {
      case "single collection":
        return history.push(`/collections/${CollectionLink}`)
      case "single category":
        return history.push({
          pathname: "/shop-list",
          query: {
            cat: CategoryLink,
            isProduct: false,
          },
        })

      default:
        break
    }
  }

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <div>
            <h3
              // style={{ fontSize: 20 }}
              className="mb-4 fontPlayfair sm:mb-10px"
            >
              {language?.title}
            </h3>
            {/* <h3 className="fontPlayfair">{language?.title}</h3> */}
            <div className="d-flex custom_small_slider">
              {width >= 480 && (
                <>
                  {groupDetail?.images && groupDetail?.images[0]?.url && (
                    <div className="pt-0 mb-3 border-radius-15 custom_banner">
                      <div
                        className="mobileBanner banner-img wow animate__animated animate__fadeInUp h-100"
                        data-wow-delay="0"
                      >
                        <img
                          src={useCloudinary(
                            groupDetail?.images[0]?.url
                              ? groupDetail?.images[0]?.url
                              : handleBannerData?.bannerImages[0]?.url,
                            "w_500,h_500"
                          )}
                          className="h-100 border-radius-15"
                          alt=""
                          onClick={handleRouteLink}
                        />
                        {/* <div className="banner-text">
                    <h4 className="mb-0">&nbsp;</h4>
                    <Link
                      to={`/custom/collection/${handleBannerData?.primaryLang?.title?.replace(
                        / /g,
                        "-"
                      )}`}
                    >
                      <span className="btn btn-xs text-white">
                        Shop Now <i className="fi-rs-arrow-small-right"></i>
                      </span>
                    </Link>
                  </div> */}
                      </div>
                    </div>
                  )}
                </>
              )}

              <SliderList2 products={product} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductG4

ProductG4.propTypes = {
  groupDetail: PropTypes.object,
}
