import {
  GET_CATEGORIES,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_MAIN_CATEGORIES_SUCCESS,
  GET_ALL_GROUPED_CATEGORIES_SUCCESS,
  GET_MAIN_GROUPED_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_SINGLE_CATEGORY_FAIL,
  GET_CATEGORY_NESTEDLOOP,
  GET_CATEGORY_NESTEDLOOP_SUCCESS,
  GET_CATEGORY_NESTEDLOOP_FAIL,
  SHOW_ALL_CATEGORIES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  categories: [],
  groupCategories: [],
  mainCategories: [],
  subCategories: [],
  mainGroupCategories: [],
  singleCategory: [],
  error: {},
  loading: false,
  nestedCategoryProd: [],
  nestedLoad: false,
  showAll: [],
}

const Classification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
    case GET_SINGLE_CATEGORY:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      }
    case GET_ALL_GROUPED_CATEGORIES_SUCCESS:
      return {
        ...state,
        groupCategories: action.payload,
        loading: false,
      }
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: action.payload,
        loading: false,
      }
    case GET_MAIN_CATEGORIES_SUCCESS:
      return {
        ...state,
        mainCategories: action.payload,
        loading: false,
      }
    case GET_MAIN_GROUPED_CATEGORIES_SUCCESS:
      return {
        ...state,
        mainGroupCategories: action.payload,
        loading: false,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        singleCategory: action.payload,
        loading: false,
      }

    case GET_SINGLE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CATEGORY_NESTEDLOOP:
      return {
        ...state,
        nestedLoad: true,
      }
    case GET_CATEGORY_NESTEDLOOP_SUCCESS:
      return {
        ...state,
        nestedCategoryProd: action.payload,
        nestedLoad: false,
      }

    case GET_CATEGORY_NESTEDLOOP_FAIL:
      return {
        ...state,
        error: action.payload,
        nestedLoad: false,
      }

    case SHOW_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        showAll: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Classification
