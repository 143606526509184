import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PRODUCTS,
  GET_PRODUCT_BY_CATEGORY,
  GET_PRODUCT_DETAIL,
  GET_RELATED_PRODUCT,
} from "./actionTypes"
import {
  getProductsSuccess,
  getProductsFail,
  getProductDetailSuccess,
  getProductDetailFail,
  getProductsByCategorySuccess,
  getProductsByCategoryFail,
  getRelatedProductsSuccess,
  getRelatedProductsFail,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"

//products
function getProductsAPi({ searchText, page, custom, sort, limit }) {
  return get(
    `/product/user/custom/${custom ? custom : "all"}?page=${
      page ? page : 1
    }&sort=${sort ? sort : ""}&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ""
    }`
  )
}

function getProductsByCategoryAPi({ searchText, page, custom, sort, limit }) {
  return get(
    `/product/user/category/name/${custom ? custom : ""}?page=${
      page ? page : 1
    }&sort=${sort ? sort : ""}&limit=${limit ? limit : 10}&search=${
      searchText ? searchText : ""
    }`
  )
}

const getProductDetailsAPi = productName => {
  return get(`/product/user/single/name/${productName}`)
}

const getRelatedProductAPi = productName => {
  return get(`/product/user/related/name/${productName}`)
}

//products
function* fetchProducts({ payload }) {
  try {
    const response = yield call(getProductsAPi, payload)
    yield put(getProductsSuccess(response))
  } catch (error) {
    yield put(getProductsFail(error))
  }
}
function* fetchProductsByCategory({ payload }) {
  try {
    const response = yield call(getProductsByCategoryAPi, payload)
    yield put(getProductsByCategorySuccess(response))
  } catch (error) {
    yield put(getProductsByCategoryFail(error))
  }
}

function* fetchProductDetail({ productName }) {
  try {
    const response = yield call(getProductDetailsAPi, productName)
    yield put(getProductDetailSuccess(response))
  } catch (error) {
    yield put(getProductDetailFail(error))
  }
}

//related product
function* fetchRelatedProduct({ payload }) {
  try {
    const response = yield call(getRelatedProductAPi, payload)
    yield put(getRelatedProductsSuccess(response))
  } catch (error) {
    yield put(getRelatedProductsFail(error))
  }
}

function* productsSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts)
  yield takeEvery(GET_PRODUCT_BY_CATEGORY, fetchProductsByCategory)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  yield takeEvery(GET_RELATED_PRODUCT, fetchRelatedProduct)
}

export default productsSaga
