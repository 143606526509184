import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"

// Import Routes all
import { publicRoutes, AuthProtectedRoute } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"
import Preloader from "components/elements/Preloader"

import "react-perfect-scrollbar/dist/css/styles.css"
import "react-responsive-modal/styles.css"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "./assets/css/main.css"
import "./assets/css/custom.css"
import "./assets/sass/custom.scss"

import { Notification } from "components/Common/Notification"
import LoginPopup from "pages/user/LoginPopup"
import { getFavouriteItems, getUser, setLanguageAction } from "store/actions"
import CartBottomNotification from "components/elements/cartBottomNotification"
import { getSiteSettings } from "store/settings/actions"
import UesWindowScrollTop from "constants/uesWindowScrollTop"
import MobileNav from "components/layout/MobileNav"
import storage from "util/localStorage"
import { local_token } from "helpers/api_methods"

const App = props => {
  const token = localStorage.getItem(local_token)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const { isLoginPopup, cartNoti, favourites } = useSelector(state => ({
    isLoginPopup: state.Layout.isLoginPopup,
    cartNoti: state.Layout.cartNoti,
    favourites: state.Cart.favourites,
  }))

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(getUser())
    }
  }, [token])

  useEffect(() => {
    dispatch(setLanguageAction("en"))
  }, [dispatch])

  useEffect(() => {
    dispatch(getSiteSettings())
  }, [dispatch])

  useEffect(() => {
    if (isLoginPopup === true) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isLoginPopup])

  useEffect(() => {
    dispatch(getFavouriteItems(1, 20))
  }, [dispatch])

  useEffect(() => {
    const favIds = favourites?.favourites?.map(fav => fav.product?._id)
    if (favIds?.length >= 1) {
      storage.set("user_favourite", favIds)
    }
  })

  return (
    <React.Fragment>
      <Notification />
      {isLoginPopup && <LoginPopup />}

      {loading ? (
        <Preloader />
      ) : (
        <Router>
          <MobileNav />
          <UesWindowScrollTop />
          {cartNoti && <CartBottomNotification />}
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {AuthProtectedRoute?.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            <Route render={() => <Redirect to={{ pathname: "/404" }} />} />,
          </Switch>
        </Router>
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
