import CollectionList from "components/ecommerce/CollectionList"
import React from "react"
import PropTypes from "prop-types"
import { useBanner } from "./useBannerData"

function CollectionG1({ groupDetail, col, isGrid }) {
  const banner = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <section className={"section-padding " + `${isGrid && "gridStyle"}`}>
        <div
          className="section-title mb-4 wow animate__animated animate__fadeIn"
          data-wow-delay="0"
        >
          {!col && (
            <h3 className="mb-0 fontPlayfair pb-0 ">
              {groupDetail?.primaryLang?.title}
            </h3>
          )}
        </div>
        {isGrid && (
          <div
            style={{ display: "block", textAlign: "center" }}
            className="mb-4 sm:py-30 wow animate__animated animate__fadeIn"
          >
            <h3 style={{ fontSize: 20 }} className="mb-0 pb-0 sm:font-size-16">
              {groupDetail?.primaryLang?.title}
            </h3>
            <p>{groupDetail?.primaryLang?.description}</p>
          </div>
        )}
        <div className="row" style={{ justifyContent: "space-center",margin:'auto' }}>
          <CollectionList isGridItem={true} listData={banner} col={col} />
        </div>
    </section>
  )
}

export default CollectionG1

CollectionG1.propTypes = {
  groupDetail: PropTypes.object,
  col: PropTypes.number,
  isGrid: PropTypes.bool,
}
