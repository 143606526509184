import React from "react"
import { IoIosArrowUp } from "react-icons/io"

function ScrollTop() {
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  return (
    <div id="chat-btn" className="d-flex justify-content-end">
      <div
        style={{
          position: "fixed",
          bottom: "65px",
          right: "20px",
          width: "40px",
          height: "40px",
          backgroundColor: "#dee2e6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          zIndex: 100,
        }}
      >
        <IoIosArrowUp
          onClick={scrollUp}
          style={{
            cursor: "pointer",
          }}
          size={"30px"}
          color="#c42645"
        />
      </div>
    </div>
  )
}

export default ScrollTop
