import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { map } from "lodash"
import { useWindowSize } from "react-use"
import useCloudinary from "constants/UseCloudinaryImage"

function FeatchDeals({ listData, col, sm }) {
  const { width } = useWindowSize()
  const history = useHistory()

  const handleRouteLink = name => {
    if (name?.title) {
      const CollectionLink = name?.title?.replace(/ /g, "-")
      return history.push(`/collections/${CollectionLink}`)
    } else if (name?.name) {
      const CategoryLink = name?.name?.replace(/ /g, "-")
      return history.push({
        pathname: "/shop-list",
        query: {
          cat: CategoryLink,
          isProduct: false,
        },
      })
    } else return
  }

  return (
    <>
      {width <= 420 ? (
        <div className={`row ${col === 2 && "isHighlight"}`}>
          {map(listData, (item, key) => (
            <div className={"col sm-3"} key={key}>
              <div
                onClick={() => handleRouteLink(item?.primaryLang)}
                className="cursor-pointer"
                style={{ overflow: "scroll" }}
              >
                <img
                  src={useCloudinary(item?.bannerImages[0]?.url, "")}
                  alt=""
                  className=""
                  style={{ borderRadius: "20%" }}
                />
                <span className="fontPlayfair font-size-12 d-flex align-items-center justify-content-center">
                  {item?.primaryLang?.title
                    ? item?.primaryLang?.title
                    : item?.primaryLang?.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={`row d-flex align-items-center justify-content-center ${
            col === 12 && "isHighlight"
          }`}
        >
          {map(listData, (item, key) => (
            <div
              className={"col-lg-4 col-md-6 col-xl-" + `${col ? col : 4}`}
              key={key}
            >
              <div
                className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp mb-4 sm:mb-0"
                data-wow-delay="0"
              >
                <div className="product-img-action-wrap">
                  <div className="product-img ">
                    <span
                      onClick={() => handleRouteLink(item?.primaryLang)}
                      className="h-100 w-100 cursor-pointer category-mobile-image"
                    >
                      {col === 6 ? (
                        <img
                          src={useCloudinary(
                            item?.bannerImages[0]?.url,
                            "w_1500"
                          )}
                          alt=""
                          className="fit-cover object-end mobileBanner"
                        />
                      ) : (
                        <img
                          src={item?.bannerImages[0]?.url}
                          alt=""
                          className="fit-cover object-end mobileBanner "
                        />
                      )}
                    </span>
                  </div>
                </div>
                {!sm && (
                  <div
                    className="max-w:300px m-auto mb-4"
                    style={
                      {
                        // marginLeft: col && "1rem",
                        // marginTop: col && "-110px",
                      }
                    }
                  >
                    <div className="deals-content horizontal-deals-content text-center">
                      {col !== 12 && (
                        <h3 className="mb-1 mt-3 fontPlayfair sm:font-size-24">
                          <Link to={"#!"}>
                            <span>
                              {item?.primaryLang?.title
                                ? item?.primaryLang?.title
                                : item?.primaryLang?.name}
                            </span>
                          </Link>
                        </h3>
                      )}
                      <p>{item?.primaryLang?.description}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
export default FeatchDeals

FeatchDeals.propTypes = {
  col: PropTypes.number,
  sm: PropTypes.bool,
  listData: PropTypes.any,
}
