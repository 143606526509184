import React from "react"
import { IoCheckbox } from "react-icons/io5"
import { MdCheckBoxOutlineBlank } from "react-icons/md"
import DashLine from "components/Common/Icons/DashLine"
import { useSelector } from "react-redux"
import moment from "moment"
import usePrice from "constants/UsePrice"

function TrackOrder() {
  const { order } = useSelector(state => ({
    order: state.Cart.order,
  }))

  const { currencyCode } = usePrice()

  const status = order?.orderStatus

  const handleOrderStatus = () => {
    const colors = {
      col1: "#adadaddb",
      col2: "#adadaddb",
      col3: "#adadaddb",
    }
    if (status === "Processing") return { ...colors, col1: "#3BB77E" }
    if (status === "Shipped")
      return { ...colors, col1: "#3BB77E", col2: "#3BB77E" }
    if (status === "Delivered")
      return { ...colors, col1: "#3BB77E", col2: "#3BB77E", col3: "#3BB77E" }

    return colors
  }

  const handleCheckIcon = () => {
    const checked = {
      s1: false,
      s2: false,
      s3: false,
    }
    if (status === "Processing") return { ...checked, s1: true }
    if (status === "Shipped") return { ...checked, s1: true, s2: true }
    if (status === "Delivered")
      return { ...checked, s1: true, s2: true, s3: true }

    return checked
  }

  const today = new Date()

  const todayDate = moment(today).format("DD MM YYYY")

  const orderedDate = moment(order?.createdAt).format("DD MM YYYY")

  return (
    <div className="mb-40 mobile_track">
      <h5 className="text-brand mb-30">
        {order?.totalDiscount >= 1 && (
          <p className="text-brand">
            You saved&nbsp;&nbsp;{currencyCode} {order?.totalDiscount}
          </p>
        )}
      </h5>
      <div className="d-flex min-h-100px max-w:500px">
        <div className="px-2 ">
          {handleCheckIcon()?.s1 ? (
            <IoCheckbox color={handleOrderStatus()?.col1} size={"2rem"} />
          ) : (
            <MdCheckBoxOutlineBlank
              color={handleOrderStatus()?.col2}
              size={"2rem"}
            />
          )}

          <DashLine color={handleOrderStatus()?.col1} />
        </div>
        <div>
          <h6>
            Order Placed{" "}
            {orderedDate === todayDate
              ? "Today " + moment(order?.createdAt).format("LT")
              : moment(order?.createdAt).format("dddd, DD MMMM LT")}
          </h6>
          <p>
            {order?.orderItems?.length}&nbsp;{" "}
            {order?.orderItems?.length === 1 ? "item" : "items"}{" "}
          </p>
          {/* {map(order?.orderItems, (item, key) => (
            <Fragment key={key}>
              <p className="m-0 p-0 text-warning">
                <BsArrowReturnRight /> {item?.quantity}{" "}
                {item?.primaryLang?.name} ({item?.varientValue}
                {item?.varientUnit})
              </p>
            </Fragment>
          ))} */}
        </div>
      </div>

      <div className="d-flex min-h-100px max-w:500px">
        <div className="px-2 ">
          {handleCheckIcon()?.s2 ? (
            <IoCheckbox color={handleOrderStatus()?.col2} size={"2rem"} />
          ) : (
            <MdCheckBoxOutlineBlank
              color={handleOrderStatus()?.col2}
              size={"2rem"}
            />
          )}
          <DashLine color={handleOrderStatus()?.col2} />
        </div>
        <div>
          <h6
            className={`${
              order?.orderStatus === "Shipped" ? "text-black" : "text-gray"
            }`}
          >
            In Transit{" "}
          </h6>
          {order?.orderStatus === "Shipped" && <p>Your order is on the way </p>}
        </div>
      </div>

      <div className="d-flex min-h-100px max-w:500px">
        <div className="px-2 ">
          {handleCheckIcon()?.s3 ? (
            <IoCheckbox color={handleOrderStatus()?.col3} size={"2rem"} />
          ) : (
            <MdCheckBoxOutlineBlank
              color={handleOrderStatus()?.col3}
              size={"2rem"}
            />
          )}
          {/* <DashLine color={handleOrderStatus()?.col3} /> */}
        </div>
        <div>
          <h6
            className={`${
              order?.orderStatus === "Delivered" ? "text-black" : "text-gray"
            }`}
          >
            Item Delivered{" "}
          </h6>
          {order?.orderStatus === "Delivered" && (
            <p className="mt-2 DeliveredAdd">
              {moment(order?.deliveredAt).format("dddd, DD MMM YYYY")}
              <br /> Delivered to {order?.shippingInfo?.name}
              <br />
              {order?.shippingInfo?.address}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrackOrder
