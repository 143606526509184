import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { FaHeart, FaRegHeart, FaSpinner } from "react-icons/fa"
import moment from "moment"
import getDifferenceInDays from "constants/getDifferenceInDays"
import { defualtProd } from "assets/imgs/logos"
import useWishlistAction from "constants/useWishlistAction"
import storage from "util/localStorage"
import { useWindowSize } from "react-use"
import useCloudinary from "constants/UseCloudinaryImage"
import { BsCart3 } from "react-icons/bs"
import useCartActions from "constants/useCartAction"
import { getLocalCart } from "store/actions"
import { local_token } from "helpers/api_methods"

const ProductCardWithAddtocart = ({ product, className }) => {
  const dispatch = useDispatch()

  const { addOrRemoveLoad } = useSelector(state => ({
    addOrRemoveLoad: state.Cart.addOrRemoveLoad,
  }))

  const user_favourite =
    storage.get("user_favourite") !== null && storage.get("user_favourite")
  const token = localStorage.getItem(local_token)

  const handleWishlist = productId => {
    useWishlistAction(dispatch, productId)
  }

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, stock, currentVarient } =
    usePrice(product)

  const createdAt = moment(product?.createdAt).format("MM/DD/YYYY")
  const today = moment(new Date()).format("MM/DD/YYYY")

  const diffInDays = getDifferenceInDays(createdAt, today)

  const removeSpace = language?.name?.replace(/ /g, "-")

  const discountPercentage = Math.floor(((oldPrice - price) / price) * 100)

  const { width } = useWindowSize()

  const handleClick = event => {
    switch (event.detail) {
      case 1: {
        console.log("first")
        break
      }
      case 2: {
        console.log("2ed")

        handleWishlist(product?._id)
        break
      }
      default: {
        break
      }
    }
  }
  function getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const handleCart = product => {
    const options = {
      product: product?._id,
      varient: product?.varients[0]?._id,
      operation: "add",
    }
    if (stock >= 1) {
      useCartActions(options, dispatch, product)
      if (!token) {
        dispatch(getLocalCart())
      }
    }
  }

  return (
    <>
      <div
        className={`product-cart-wrap shoplist-child mb-15 bg-${getRandomNumberBetween(
          9,
          16
        )} ${className}`}
      >
        <div
          className={`product-img-action-wrap shoplist flex-1 position-relative`}
          onClick={handleClick}
        >
          <div className="product-img product-img-zoom">
            <Link to={width >= 480 ? `/products/${removeSpace}` : ""}>
              <img
                onClick={handleClick}
                className="default-img"
                src={`${
                  useCloudinary(product.images[0]?.url, "w_500") ?? defualtProd
                }`}
                alt=""
              />
              {width >= 480 ? (
                <img
                  className="hover-img"
                  src={
                    useCloudinary(
                      product.images[1]?.url
                        ? product.images[1]?.url
                        : product.images[0]?.url,
                      "w_500"
                    ) ?? defualtProd
                  }
                  alt=""
                />
              ) : (
                ""
              )}
            </Link>
          </div>

          <div className="product-action-1">
            {/* <Link
              to={`/products/${removeSpace}`}
              aria-label="Quick view"
              className="action-btn hover-up"
            >
              <i className="fi-rs-eye"></i>
            </Link> */}
            <a
              aria-label="Add To Wishlist"
              className="action-btn hover-up"
              onClick={e => handleWishlist(product?._id)}
            >
              {user_favourite?.length >= 1 &&
              user_favourite?.includes(product?._id) ? (
                <FaHeart
                  className="text-danger"
                  style={{ marginTop: "-6px" }}
                />
              ) : (
                <FaRegHeart
                  className="text-black"
                  style={{ marginTop: "-6px" }}
                />
              )}
            </a>
          </div>

          <div
            className="product-badges product-badges-position 
          product-badges-mrg"
          >
            {stock <= 0 ? (
              <span className="text-white best curvy" style={{ zIndex: 5 }}>
                ! Out Of Stock
              </span>
            ) : (
              <>
                {product?.isFeatured && <span className="hot curvy">Hot</span>}
                {diffInDays <= 2 && <span className="new curvy">New</span>}
              </>
            )}
          </div>
        </div>
        <div className="product-content-wrap pt-1 w-100">
          <h2>
            <Link
              to={`/products/${removeSpace}`}
              className="font-normal"
              style={{ fontWeight: "normal" }}
            >
              <span>
                {language?.name?.slice(0, 80)}
                {language?.name?.length >= 80 && "..."}
                {currentVarient && (
                  <span style={{ whiteSpace: "nowrap" }}>
                    &nbsp; ({currentVarient})
                  </span>
                )}{" "}
              </span>
            </Link>
          </h2>

          <div className="product-card-bottom mt-0">
            <div className="product-price pt-0">
              {oldPrice && (
                <>
                  <span
                    className="old-price font-normal m-0"
                    style={{ fontWeight: "400" }}
                  >
                    {oldPrice}
                  </span>
                  &nbsp;
                </>
              )}
              <span
                className="font-head text-black"
                style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
              >
                {currencyCode}
                {price}&nbsp;
              </span>
              {(product?.discountPercentage > 0 || discountPercentage > 0) && (
                <span className="price-per">
                  {product?.discountPercentage ?? discountPercentage}% off
                </span>
              )}
            </div>
          </div>
          <button
            onClick={e => handleCart(product)}
            className={
              "button button-add-to-cart-2 w-100 d-flex align-items-center justify-content-center " +
              `${
                stock <= 0 &&
                "bg-gray text-white opacity-half cursor-notAllowed"
              }`
            }
          >
            <BsCart3 className="mx-0.5" />
            Add to cart{" "}
            {addOrRemoveLoad && <FaSpinner className="mx-2 spinner" />}
          </button>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardWithAddtocart)

ProductCardWithAddtocart.propTypes = {
  product: PropTypes.any,
  className: PropTypes.string,
}
