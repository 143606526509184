import { local_token } from "helpers/api_methods"
import { map } from "lodash"
import {
  addOrRemove,
  showCartNotificationAction,
  showToasterAction,
} from "store/actions"
import storage from "util/localStorage"

const useCartActions = (options, dispatch, product) => {
  const oldCartitems =
    storage.get("user-cart-item") !== null && storage.get("user-cart-item")

  console.log("oldCartitems", oldCartitems)

  const token = localStorage.getItem(local_token)

  const currentVarient = product?.varients?.filter(
    v => v._id === options.varient
  )

  const productModal = {
    primaryLang: product?.primaryLang,
    secondaryLang: product?.secondaryLang,
    images: product?.images?.slice(0, 1),
    _id: product?._id,
    varients: currentVarient ? currentVarient : [],
    hasVarients: product?.hasVarients,
  }

  const cartItem = {
    ...options,
    product: productModal,
    varient: currentVarient[0]?._id,
    varientValue: currentVarient[0]?.value,
    varientUnit: currentVarient[0]?.unit,
    price: product?.hasVarients ? currentVarient[0]?.price : product?.price,
    quantity: options?.quantity ? options?.quantity : 1,
    _id: product?._id,
  }

  const cartItemIds = oldCartitems && oldCartitems?.map(i => i._id)
  const quantity = options?.quantity ? options?.quantity : 1

  const newItem = map(oldCartitems, item => {
    if (item?._id === cartItem?._id) {
      return {
        ...item,
        quantity:
          options?.operation === "add"
            ? item?.quantity + quantity
            : options?.operation === "substract"
            ? item?.quantity - quantity
            : "",
      }
    } else {
      return item
    }
  })

  if (token) {
    dispatch(addOrRemove(options))
  } else {
    if (oldCartitems) {
      storage.set(
        "user-cart-item",
        cartItemIds?.includes(product?._id) === true
          ? newItem
          : [...newItem, cartItem]
      )
      dispatch(
        showToasterAction({
          data: { title: "Cart", message: "Cart Updated" },
          type: "success",
        })
      )
      dispatch(showCartNotificationAction())
    } else {
      storage.set("user-cart-item", [cartItem])
      dispatch(
        showToasterAction({
          data: { title: "Cart", message: "Cart Updated" },
          type: "success",
        })
      )
      dispatch(showCartNotificationAction())
    }
  }
}

export default useCartActions

export const useLocalCart = () => {
  return storage.get("user-cart-item")
}

export const clearLocalCart = () => {
  return storage.delete("user-cart-item")
}

export const useLocalCartPirces = () => {
  const localCart = useLocalCart()

  const localCartSum =
    localCart?.length >= 1 && localCart?.map(i => i?.price * i?.quantity)

  const itemTotal = localCartSum && localCartSum?.reduce((x, y) => x + y, 0)

  return {
    itemTotal,
  }
}
