import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBlogs } from "store/actions"
import useCloudinary from "constants/UseCloudinaryImage"
import { useWindowSize } from "react-use"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Card, CardBody } from "reactstrap"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

function BlogList({}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { blogs, loading } = useSelector(state => ({
    blogs: state.Blogs.blogs,
    loading: state.Blogs.loading,
  }))

  console.log("data :", blogs)

  useEffect(() => {
    dispatch(getBlogs("highlighted"))
  }, [dispatch])

  const { width } = useWindowSize()

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 1.1
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 3.5
  }

  const handleSingleViewClick = blog => {
    const removeSpace = blog?.title?.replace(/ /g, "-")

    history.push({
      pathname: `/blog/${removeSpace}`,
      state: {
        ...location.query,
        blogId: blog?._id,
      },
    })
  }

  return (
    <section
      className="section-padding mb-4"
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "30px",
      }}
    >
      <div className="container">
        <div
          className="section-title mb-4 wow animate__animated animate__fadeIn position-relative"
          data-wow-delay="0"
          style={{ display: "block", textAlign: "center" }}
        >
          <h3 className="sm:font-size-16 m-0 fontPlayfair">
            {"Design your home title"}
          </h3>
          <p className="sm:pb-20">{"Connect Deep with Lighting World"}</p>
          {/* <button
            className={"button button-add-to-cart  "}
            style={{
              background: "#fff",
              color: "#342f60",
              position: "absolute",
              right: 0,
              bottom: 10,
              textDecoration: "underline",
            }}
            onClick={() => history.push("/blogs")}
          >
            See all blogs
          </button> */}
          {/* <span className="d-flex align-items-flex-end justify-content-end ">
            <Link to="/blogs">
              <span
                className={"  "}
                style={{
                  color: "#342f60",
                  textDecoration: "underline",
                }}
              >
                See More Lighting Ideas
              </span>
            </Link>
          </span> */}
        </div>

        <div className="row ">
          <Swiper
            spaceBetween={10}
            slidesPerView={handleWindowSizeVsSliderPreview()}
            grid={{
              rows: 2,
            }}
            keyboard={{ enabled: true }}
            direction="horizontal"
            // mousewheel={true}
            navigation={{
              prevEl: ".custom_prev_f",
              nextEl: ".custom_next_f",
            }}
            autoplay={false}
            speed={600}
            className="text-center"
            // breakpoints={{
            //   480: {
            //     slidesPerView: 2,
            //   },
            //   640: {
            //     slidesPerView: 2.5,
            //   },
            //   768: {
            //     slidesPerView: 3.5,
            //   },
            //   1024: {
            //     slidesPerView: 5,
            //   },
            // }}
          >
            {blogs?.blogs?.map((item, i) => (
              <SwiperSlide
                key={i}
                className={` ${width >= 420 ? "wid-wid" : "w-100 h-100"}`}
              >
                <Card
                  className={`card ${
                    width >= 420 ? "box-shadow" : "box-shadow-mobiles"
                  }`}
                  onClick={() => handleSingleViewClick(item)}
                >
                  <img
                    src={useCloudinary(item?.images[0]?.url, "w_800")}
                    alt=""
                    className=""
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: 500,
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  />
                  <div className="px-2 mt-4 ">
                    <p
                      className="text px-3 text-black"
                      style={{
                        fontSize: 17,
                        textAlign: "start",
                        fontWeight: 700,
                      }}
                    >
                      {item?.title}
                    </p>
                    <p
                      className="px-3 pt-2 mb-3 font-size-14"
                      style={{ textAlign: "start", color: "#8a8a8a" }}
                    >
                      {item?.description?.slice(0, 60)}
                      {item?.description?.length > 60 && "..."}
                      &nbsp;&nbsp;
                      <span
                        className="text-black"
                        onClick={() => handleSingleViewClick(item)}
                      >
                        read more
                      </span>
                    </p>
                  </div>
                </Card>
                {/* <div
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSingleViewClick(item)}
                >
                  <div
                    className="wow animate__animated animate__fadeInUp h:350px"
                    data-wow-delay="0"
                  >
                    <img
                      src={useCloudinary(item?.images[0]?.url, "w_800")}
                      alt=""
                      className="h:300px w-100"
                    />
                    <p
                      className="text-black m-0"
                      style={{ lineHeight: "20px" }}
                    >
                      {item?.title}
                    </p>
                    <p className="text-muted " style={{ lineHeight: "17px" }}>
                      {item?.description?.slice(0, 35)}
                      &nbsp;
                      {item?.description?.length > 35 && "..."}
                    </p>
                  </div>
                </div> */}
              </SwiperSlide>
            ))}

            <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
              <span className="slider-btn slider-prev slick-arrow custom_prev_f">
                <i className="fi-rs-arrow-small-left"></i>
              </span>
              <span className="slider-btn slider-next slick-arrow custom_next_f">
                <i className="fi-rs-arrow-small-right"></i>
              </span>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default BlogList

BlogList.propTypes = {
  groupDetail: PropTypes.object,
}
