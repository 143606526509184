import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useWindowSize } from "react-use"
import PropTypes from "prop-types"

function BannerLoader({ innerClass }) {
  const { width } = useWindowSize()

  return (
    <>
      <Skeleton
        containerClassName={"d-flex container p-0 h-90"}
        count={1}
        className={"mb-3 w-100 m-auto " + `${innerClass}`}
        style={{
          height: "100%",
          minHeight: width >= 1200 ? "100vh" : "300px",
          borderRadius: "0px",
          backgroundColor: "#c02342",
        }}
        duration={2}
      />
    </>
  )
}

export default BannerLoader

BannerLoader.propTypes = {
  innerClass: PropTypes.string,
}
