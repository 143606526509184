import React, { useEffect } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useDispatch, useSelector } from "react-redux"
import { getBanners } from "store/actions"
import { map } from "lodash"

import BannerLoader from "components/Common/loaders/BannerLoader"

import "swiper/swiper.min.css"
import "swiper/swiper-bundle.min.css"
import { Link } from "react-router-dom"
import useCloudinary from "constants/UseCloudinaryImage"
import { useWindowSize } from "react-use"

SwiperCore.use([Navigation, Pagination])

const Intro1 = () => {
  const dispatch = useDispatch()

  const { banner, loading } = useSelector(state => ({
    banner: state.Banner.banners,
    loading: state.Banner.loading,
  }))

  useEffect(() => {
    dispatch(getBanners())
  }, [dispatch])
  const { width, height } = useWindowSize()

  const heroBanner = banner?.mainBanners

  const handleBannerImages = image => {
    if (width >= 480) {
      return `url(${useCloudinary(image?.webImages[0]?.url, `w_2000`)})`
    } else return `url(${useCloudinary(image?.appImages[0]?.url, `w_1500`)})`
  }

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        autoplay={true}
        pagination={false}
        navigation={{
          prevEl: ".custom_prev_i1",
          nextEl: ".custom_next_i1",
        }}
        className={`hero-slider-1 style-4 dot-style-1 dot-style-1-position-1 ${
          heroBanner?.length >= 2 ? "" : "onlyOneBanner"
        }`}
        speed={400}
        loop={true}
        // onlyOneBanner
      >
        {loading ? (
          <SwiperSlide>
            <BannerLoader innerClass="redBanner" />
          </SwiperSlide>
        ) : (
          <>
            {/* {map(heroBanner, (ban, key) => (
              <SwiperSlide key={key}>
                <Link
                  to={
                    ban?.link?.length > 1
                      ? `/collections/${ban?.link?.replace(/ /g, "-")}`
                      : ""
                  }
                >
                  <div
                    className="scorio_slider single-hero-slider single-animation-wrap"
                    // style={{
                    //   backgroundImage: `url(${useCloudinary(
                    //     ban?.images[0]?.url,
                    //     `q_auto:eco`
                    //   )})`,
                    // }}
                  >
                    <img
                    style={{objectFit:'cover'}}
                      src={useCloudinary(ban?.webImages[0]?.url, `q_auto:eco`)}
                      className="w-100  border-radious-0"
                    />
                    <div className="slider-content"></div>
                  </div>
                </Link>
              </SwiperSlide>
            ))} */}

            {map(heroBanner, (ban, key) => (
              <SwiperSlide key={key}>
                <Link
                  to={
                    ban?.link?.length > 1
                      ? `/collections/${ban?.link?.replace(/ /g, "-")}`
                      : ""
                  }
                >
                  <div
                    className="single-hero-slider single-animation-wrap"
                    style={{
                      backgroundImage: handleBannerImages(ban),
                    }}
                  >
                    <div className="slider-content"></div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>

      <div className="slider-arrow hero-slider-1-arrow scorio_arrow">
        <span className="slider-btn slider-prev slick-arrow custom_prev_i1">
          <i className="fi-rs-angle-left"></i>
        </span>
        <span className="slider-btn slider-next slick-arrow custom_next_i1">
          <i className="fi-rs-angle-right"></i>
        </span>
      </div>
    </>
  )
}

export default Intro1
