import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import React from "react"
import { useSelector } from "react-redux"
import { map } from "lodash"

function Youmaylike() {
  const { products } = useSelector(state => ({
    products: state.Products.productList,
  }))

  if (products?.length >= 1) {
    return (
      <div>
        {" "}
        <h3 className="mb-3 w-100 text-gray font-mainHead">You may like</h3>
        <div className="d-flex flex-wrap">
          {map(products, (product, key) => (
            <div
              className="mb-md-0 mx-1 sm:mx-0.5 sm:max-w-47  max-w:200px wow animate__animated animate__fadeInUp"
              data-wow-delay="0"
              key={key}
            >
              <ProductCardOne product={product} />
            </div>
          ))}
        </div>
      </div>
    )
  } else return <></>
}

export default Youmaylike
