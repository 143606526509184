import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBlogs } from "store/actions"
import useCloudinary from "constants/UseCloudinaryImage"
import { useWindowSize } from "react-use"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { useLocation } from "react-router-dom"
import store1 from "assets/imgs/stores/store1.jpg"
import store2 from "assets/imgs/stores/store2.jpg"
import store3 from "assets/imgs/stores/store3.jpg"
import store4 from "assets/imgs/stores/store4.jpg"
import store5 from "assets/imgs/stores/store5.jpeg"
import store6 from "assets/imgs/stores/store6.jpeg"
import { Card } from "reactstrap"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

function StoreList({}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { blogs, loading } = useSelector(state => ({
    blogs: state.Blogs.blogs,
    loading: state.Blogs.loading,
  }))

  console.log("data :", blogs)

  useEffect(() => {
    dispatch(getBlogs("highlighted"))
  }, [dispatch])

  const { width } = useWindowSize()

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2.1
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 3.5
  }

  const handleSingleViewClick = blog => {
    const removeSpace = blog?.title?.replace(/ /g, "-")

    history.push({
      pathname: `/blog/${removeSpace}`,
      state: {
        ...location.query,
        blogId: blog?._id,
      },
    })
  }

  const stores = [
    {
      img: store4,
      description: "Build Hub, 16th Mile, Thonnakkal",
      place: "Trivandrum",
      link: "https://www.google.com/maps/place/Scorio+Lights+(+Lights+Shop+Trivandrum+%7C+All+Lighting+Solution+)/@8.6445296,76.8439523,15.5z/data=!4m14!1m7!3m6!1s0x3b05c1119ab2bf15:0xe2937532ad2e4727!2sScorio+Lights+(+Lights+Shop+Trivandrum+%7C+All+Lighting+Solution+)!8m2!3d8.6440931!4d76.8436044!16s%2Fg%2F11slhkgdc8!3m5!1s0x3b05c1119ab2bf15:0xe2937532ad2e4727!8m2!3d8.6440931!4d76.8436044!16s%2Fg%2F11slhkgdc8?entry=ttu",
    },
    {
      img: store6,
      description: "Caritas Hospital Junction,Thellakom",
      place: "Kottayam",
      link: "https://www.google.com/maps/place/scorio+lights/@10.069115,76.6096673,17z/data=!3m1!4b1!4m6!3m5!1s0x3b07e7ee8d51c2ab:0xdedc45b01f9b7e64!8m2!3d10.069115!4d76.6122422!16s%2Fg%2F11s8mzrdyx?entry=ttu",
    },
    {
      img: store5,
      description: "Thankalam, Kothamangalam, Kerala",
      place: "Ernakulam",
      link: "https://www.google.com/maps/place/scorio+lights/@10.069115,76.6096673,17z/data=!3m1!4b1!4m6!3m5!1s0x3b07e7ee8d51c2ab:0xdedc45b01f9b7e64!8m2!3d10.069115!4d76.6122422!16s%2Fg%2F11s8mzrdyx?entry=ttu",
    },
    {
      img: store3,
      description: "Lighting store in Peruvallur",
      place: "Mollappadi",
      link: "https://www.google.com/maps/place/Scorio+Lights+%7C+Light+Shop+Mollapadi,+Karuvankallu/@11.1067356,75.9559409,15z/data=!4m6!3m5!1s0x3ba64f335f92ea7d:0x15eabe75ad06854f!8m2!3d11.1067356!4d75.9559409!16s%2Fg%2F11r9w983lr?entry=ttu",
    },
    {
      img: store1,
      description: "Calicut Rd, Patterkulam",
      place: "Manjeri",
      link: "https://www.google.com/maps/place/Scorio+Lights+%7C+Light+Shop+manjeri+%7C+%7C+All+Lighting+Solution/@11.1203988,76.1086542,15z/data=!4m6!3m5!1s0x3ba6372154a857fd:0xf11006dc17163486!8m2!3d11.1203988!4d76.1086542!16s%2Fg%2F11qpm63jx1?entry=ttu",
    },
    {
      img: store2,
      description: "Calicut Rd, Patterkulam",
      place: "Manjeri",
      link: "https://www.google.com/maps/place/Scorio+Lights+%7C+Light+Shop+manjeri+%7C+%7C+All+Lighting+Solution/@11.1203988,76.1086542,15z/data=!4m6!3m5!1s0x3ba6372154a857fd:0xf11006dc17163486!8m2!3d11.1203988!4d76.1086542!16s%2Fg%2F11qpm63jx1?entry=ttu",
    },
  ]

  return (
    <section className="section-padding mb-4">
      <div className="container">
        <div
          className="section-title wow animate__animated animate__fadeIn position-relative"
          data-wow-delay="0"
          style={{ display: "block", textAlign: "center" }}
        >
          <h3 className="sm:font-size-16 m-0 fontPlayfair">{"Our Stores"}</h3>
          {/* <p className="sm:pb-20">{"Connect Deep with Lighting World"}</p> */}
          {/* <button
            className={"button button-add-to-cart  "}
            style={{
              background: "#fff",
              color: "#342f60",
              position: "absolute",
              right: 0,
              bottom: 10,
              textDecoration: "underline",
            }}
            onClick={() => history.push("/blogs")}
          >
            See all blogs
          </button> */}
        </div>
        <div className="row">
          <Swiper
            spaceBetween={10}
            slidesPerView={handleWindowSizeVsSliderPreview()}
            grid={{
              rows: 2,
            }}
            keyboard={{ enabled: true }}
            direction="horizontal"
            // mousewheel={true}
            navigation={{
              prevEl: ".custom_prev_f",
              nextEl: ".custom_next_f",
            }}
            autoplay={true}
            speed={400}
            // className="text-center"
            breakpoints={{
              480: {
                slidesPerView: 2.1,
              },
              640: {
                slidesPerView: 2.5,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4.5,
              },
            }}
          >
            {stores?.map((item, i) => (
              <SwiperSlide key={i} className="">
                {/* <div
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSingleViewClick(item)}
                > */}
                {/* <div
                  className="wow animate__animated animate__fadeInUp h:350px"
                  data-wow-delay="0"
                > */}
                {/* <Card style={{ position: "relative" }}>
                  <a href={item?.link}>
                    <img src={item?.img} alt="" className="p-1 store-image" />
                  </a>
                  <p
                    className="text-black m-0 d-flex px-2"
                    style={{ lineHeight: "20px", alignItems: "baseline" }}
                  >
                    <IoLocationOutline
                      size={28}
                      style={{
                        position: "absolute",
                        bottom: 50,
                        left: -15,
                        color: "red",
                      }}
                    />
                    {width === 768 || width === 1024
                      ? item?.description?.slice(0, 25)
                      : item?.description}
                    &nbsp;
                    {(width === 768 || width === 1024) &&
                      item?.description?.length > 25 &&
                      "..."}
                  </p>
                  <h4 className="text-black px-2 pb-2"> {item?.place}</h4>
                  <p className="text-muted " style={{ lineHeight: "17px" }}>
                      {item?.description?.slice(0, 35)}
                      &nbsp;
                      {item?.description?.length > 35 && "..."}
                    </p>
                </Card> */}
                <Card
                  className=" "
                  style={{
                    minHeight: 200,
                    borderRadius: 8,
                    maxHeight: 300,
                    border: "none",
                  }}
                >
                  <a href={item?.link}>
                    <img
                      src={item?.img}
                      alt=""
                      className="p-0 store-imag"
                      style={{
                        borderRadius: 8,
                        objectFit: "cover",
                        maxHeight: 210,
                        // maxWidth: 270,
                      }}
                    />
                  </a>
                  {/* <p
                    className="text-black m-0 d-flex px-2 mt-3"
                    style={{ lineHeight: "20px", alignItems: "baseline" }}
                  >
                    {width === 768 || width === 1024
                      ? item?.description?.slice(0, 25)
                      : item?.description}
                    &nbsp;
                    {(width === 768 || width === 1024) &&
                      item?.description?.length > 25 &&
                      "..."}
                  </p> */}
                  <span className="px-2">
                    {item?.description?.slice(0, 35)}
                  </span>

                  <h6 className="px-2 pb-2">
                    {" "}
                    <b>{item?.place}</b>
                  </h6>
                  <p className="text-muted ">
                    &nbsp;
                    {item?.description?.length > 35 && "..."}
                  </p>
                </Card>

                {/* <div className="wrapper">
                  <div className="image-spec">
                    <img src={item?.img} alt="" className="p-1 store-image" />
                    <div className="content">
                      <h1>title</h1>
                      <p>description</p>
                    </div>
                  </div>
                </div> */}
              </SwiperSlide>
            ))}
            {/* <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
              <span className="slider-btn slider-prev slick-arrow custom_prev_f">
                <i className="fi-rs-arrow-small-left"></i>
              </span>
              <span className="slider-btn slider-next slick-arrow custom_next_f">
                <i className="fi-rs-arrow-small-right"></i>
              </span>
            </div> */}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default StoreList

StoreList.propTypes = {
  groupDetail: PropTypes.object,
}
