import { useSelector } from "react-redux"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { map } from "lodash"
import category1 from "assets/imgs/category/dress.png"

// SwiperCore.use([Navigation, Autoplay])

import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import { useWindowSize } from "react-use"
import useCloudinary from "constants/UseCloudinaryImage"

const CategorySlider = () => {
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")
    e.preventDefault()

    if (catName === "all") {
      history.push({
        pathname: "/shop-list",
      })
    } else {
      history.push({
        pathname: "/shop-list",
        query: {
          ...location.query,
          cat: removeSpace,
          isProduct: false,
        },
      })
    }
  }

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))
  console.log(allCategories)
  function getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const allCategoriesWeb = allCategories?.slice(0, 10)
  // Slice the first 9 items for mobile view
  const mobileCategories = allCategories?.slice(0, 9)

  return (
    <>
      {loading ? (
        <>
          {width >= 480 ? (
            <CustomizedLoader
              style={{ height: "100%", minHeight: "200px" }}
              count={4}
              mobileCount={3}
              height={"100%"}
              innerClass={"mx-2"}
            />
          ) : (
            <ProductListLoader key={0} className="isMoblieCate mt-3" />
          )}
        </>
      ) : (
        <>
          {/* {map(allCategories?.slice(0, 10), (cat, key) => (
            <div
              key={key}
              className={`mt-4 mb-4 figure-div`}
              onClick={e => selectCategory(e, cat?.primaryLang?.name)}
            >
              <figure className="overflow-hidden mx-3">
                <a>
                  <img
                    src={
                      cat?.logoImages[0]?.url
                        ? useCloudinary(cat?.logoImages[0]?.url, "w_550")
                        : category1
                    }
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </a>
              </figure>
              <h6 
                className="pt-3 spfont"
                style={{ fontWeight: "400", textAlign: "center" }}
              >
                {cat?.primaryLang?.name}
              </h6>
            </div>
          ))} */}
          {map(allCategories?.slice(0, width >= 480 ? 10 : 10), (cat, key) => (
            <div
              key={key}
              className={`mt-4 mb-4 sm:mb-1 figure-div`}
              onClick={e => selectCategory(e, cat?.primaryLang?.name)}
            >
              <figure className="overflow-hidden mx-2 p-0">
                <a>
                  <img
                    src={
                      cat?.logoImages[0]?.url
                        ? useCloudinary(cat?.logoImages[0]?.url, "w_1000")
                        : category1
                    }
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </a>
              </figure>
              <h6
                className="pt-0   spfont"
                style={{ fontWeight: "400", textAlign: "center" }}
              >
                {cat?.primaryLang?.name}
              </h6>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default CategorySlider
// export default connect(null, { updateProductCategory })(CategorySlider)
