/* LAYOUTGROUPS */
export const GET_LAYOUTPAGES = "GET_LAYOUTPAGES"
export const GET_LAYOUTPAGES_SUCCESS = "GET_LAYOUTPAGES_SUCCESS"
export const GET_LAYOUTPAGES_FAIL = "GET_LAYOUTPAGES_FAIL"

export const GET_PAGES_HOME = "GET_PAGES_HOME"
export const GET_PAGES_HOME_SUCCESS = "GET_PAGES_HOME_SUCCESS"
export const GET_PAGES_HOME_FAIL = "GET_PAGES_HOME_FAIL"

export const GET_PAGES = "GET_PAGES"
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS"
export const GET_PAGES_FAIL = "GET_PAGES_FAIL"

export const GET_SINGLE_PAGE = "GET_SINGLE_PAGE"
export const GET_SINGLE_PAGE_SUCCESS = "GET_SINGLE_PAGE_SUCCESS"
export const GET_SINGLE_PAGE_FAIL = "GET_SINGLE_PAGE_FAIL"
