import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { AiOutlinePlus } from "react-icons/ai"
import { FaSpinner, FaTrash } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { deleteUserAddress, showToasterAction } from "store/actions"
import UserAddressEditPopup from "components/elements/userAddressPopup"

function UserAddressList({ setShippingAddress, isAccount }) {
  const dispatch = useDispatch()

  const { allAddress, loading, shippingInputs, settingLoad, user } =
    useSelector(state => ({
      allAddress: state.Cart.uesrAddress.shippingAddresses,
      loading: state.Cart.addressLoad,
      shippingInputs: state.SiteSettings.siteSettings.shippingInputs,
      settingLoad: state.SiteSettings.loading,
      user: state.Login.user,
    }))

  const [isShow, setIsShow] = useState({ status: false, data: {} })
  const [finalInput, setFinalInput] = useState({})

  const [newAddress, setnewAddress] = useState(false)
  const [addressLoad, setAddressLoad] = useState(false)

  const [selectedAddress, setSelectedAddress] = useState()

  const handleAddNewAddress = () => {
    setnewAddress(!newAddress)
  }

  const handleChangeInput = e => {
    setFinalInput({
      ...finalInput,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmitAddress = e => {
    setAddressLoad(true)
    e.preventDefault()
    setShippingAddress(finalInput)

    setTimeout(() => {
      setAddressLoad(false)
      dispatch(
        showToasterAction({
          data: {
            title: "User Address",
            message: "Shipping Address Updated Successfully",
          },
          type: "success",
        })
      )
    }, 1000)
  }

  const handleSelectAddress = item => {
    setSelectedAddress(item)
    setShippingAddress(item?.shippingInfo)
  }

  const handleDeleteAddress = id => {
    dispatch(deleteUserAddress(id))
  }

  function HandleInputType(input) {
    switch (input) {
      case "contactNumber":
      case "pinCode":
        return "number"
      case "email":
        return "email"
      default:
        return "text"
    }
  }

  useEffect(() => {
    setFinalInput({
      ...finalInput,
      name: user?.name,
      contactNumber: user?.phone,
    })
  }, [user])

  function HandleInputValue(input) {
    switch (input) {
      case "contactNumber":
        return finalInput?.phone
      case "name":
        return finalInput?.name
      case "email":
        return finalInput?.email
      case "landmark":
        return finalInput?.landmark
      case "addressLine1":
        return finalInput?.addressLine1
      case "street":
        return finalInput?.street
      case "area":
        return finalInput?.area
      case "district":
        return finalInput?.district
      case "state":
        return finalInput?.state
      case "pinCode":
        return finalInput?.pinCode
      case "city":
        return finalInput?.city
      default:
        return ""
    }
  }

  useEffect(() => {
    if (allAddress?.length <= 0) {
      setnewAddress(true)
    }
  }, [allAddress])

  return (
    <>
      <UserAddressEditPopup
        isShow={isShow?.status}
        setIsShow={setIsShow}
        data={isShow?.data}
      />
      <div className="panel-body position-relative mt-0 sm:mt-4 myAddressNew">
        <h5 className="mb-20 md:mt-50 sm:mt-0 sm:font-size-18">Delivar to</h5>
        {loading ? (
          <div className="w-100 d-flex align-items-center justify-content-center h:200px position-absolute left-0 ">
            <FaSpinner size={"1.8rem"} color="green" className="spinner" />
          </div>
        ) : (
          <form className={"d-flex flex-wrap"}>
            {map(allAddress, (item, key) => (
              <div
                className={"mb-4 custome-radio sm:w-100 " + "w-50"}
                key={key}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="payment_option"
                  id={item?._id}
                  checked={selectedAddress?._id === item?._id}
                  onChange={() => handleSelectAddress(item)}
                />
                <label
                  className="form-check-label text-size-16 text-black sm:font-size-14"
                  htmlFor={item?._id}
                >
                  +91 {item?.shippingInfo?.contactNumber}
                </label>

                <div className="mx-4 mb-1 myAddress">
                  <p className="mb-0">{item?.shippingInfo?.name}</p>
                  <p className="mb-0">{item?.shippingInfo?.email}</p>
                  <p className="mb-0">{item?.shippingInfo?.address}</p>
                  <p className="mb-0">{item?.shippingInfo?.addressLine1}</p>
                  <p className="mb-0">{item?.shippingInfo?.street}</p>
                  <p className="mb-0">{item?.shippingInfo?.landmark}</p>
                  <p className="mb-0">{item?.shippingInfo?.area}</p>
                  <p className="mb-0">{item?.shippingInfo?.district}</p>
                  <p className="mb-0">{item?.shippingInfo?.state}</p>
                </div>
                <div className="px-4 d-flex align-items-center">
                  <a
                    href="#"
                    onClick={() =>
                      setIsShow({ ...isShow, status: true, data: item })
                    }
                    className="btn-small"
                  >
                    Edit
                  </a>
                  <FaTrash
                    className="mx-2 cursor-pointer"
                    onClick={() => handleDeleteAddress(item?._id)}
                  />
                </div>
              </div>
            ))}
          </form>
        )}

        <div className="form-group">
          {!isAccount && (
            <Link
              to={"#!"}
              onClick={() => handleAddNewAddress()}
              className="d-flex align-items-center text-size-16 mb-20"
            >
              <AiOutlinePlus className="mr-1" />
              Add a New Address
            </Link>
          )}

          {newAddress && (
            <form onSubmit={handleSubmitAddress}>
              {settingLoad ? (
                <></>
              ) : (
                <div className="row">
                  {map(shippingInputs, (input, key) => (
                    <Fragment key={key}>
                      {input?.name === "address" ? (
                        <div className="form-group col-lg-6">
                          <textarea
                            name={input?.name}
                            placeholder={input?.name}
                            rows="4"
                            cols="50"
                            style={{ minHeight: "10px" }}
                            onChange={e => handleChangeInput(e)}
                            required={
                              input.required === "Required" ? true : false
                            }
                          ></textarea>
                        </div>
                      ) : (
                        <div className="form-group col-lg-6">
                          <input
                            type={HandleInputType(input?.name)}
                            name={input?.name}
                            placeholder={input?.name}
                            onChange={e => handleChangeInput(e)}
                            required={
                              input.required === "Required" ? true : false
                            }
                            value={HandleInputValue(input?.name)}
                          />
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
              <div className="w-100 d-flex">
                <button
                  type="submit"
                  className="btn btn-fill-out btn-block btn-md w-fit ml-auto mt-30"
                  style={{ borderRadius: "5px" }}
                >
                  Save Address
                  {addressLoad && <FaSpinner className="spinner mx-1" />}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default UserAddressList

UserAddressList.propTypes = {
  setShippingAddress: PropTypes.any,
  isAccount: PropTypes.bool,
}
