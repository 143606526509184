import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ProductCardOne from "../ecommerce/productCards/ProductCardOne"
import { useSelector } from "react-redux"
import ProductLoader from "components/Common/loaders/ProductLoader"
import { useWindowSize } from "react-use"

SwiperCore.use([Navigation])

const RelatedSlider = () => {
  const { width } = useWindowSize()

  const { relatedProd, loading } = useSelector(state => ({
    relatedProd: state.Products.relatedProduct,
    loading: state.Products.relatedProductLoad,
  }))

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 5.5
  }

  return (
    <>
      {loading ? (
        <ProductLoader height="200px" innerClass="mx-2" />
      ) : (
        <>
          <Swiper
            slidesPerView={handleWindowSizeVsSliderPreview()}
            spaceBetween={10}
            grid={{
              rows: 2,
            }}
            keyboard={{ enabled: true }}
            direction="horizontal"
            navigation={{
              prevEl: ".custom_prev_n",
              nextEl: ".custom_next_n",
            }}
            autoplay={true}
            speed={600}
            className=""
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 2.5,
              },
              768: {
                slidesPerView: 3.5,
              },
              1024: {
                slidesPerView: 5.5,
              },
            }}
          >
            {relatedProd?.map((product, i) => (
              <SwiperSlide key={i}>
                <ProductCardOne product={product} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="slider-arrow slider-arrow-2 carausel-6-columns-arrow">
            <span className="slider-btn slider-prev slick-arrow custom_prev_n">
              <i className="fi-rs-angle-left"></i>
            </span>
            <span className="slider-btn slider-next slick-arrow custom_next_n">
              <i className="fi-rs-angle-right"></i>
            </span>
          </div>
        </>
      )}
    </>
  )
}

export default RelatedSlider
