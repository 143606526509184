import React from "react"
import CategoryGrid from "components/elements/CategoryGrid"
import Layout from "components/layout/Layout"

function AllCategoryList() {
  return (
    <>
      <Layout parent="Home" sub="Categories" subChild="">
        <section className="mt-50 mb-50 sm:min-h-60">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12">
                <div className="loop-grid pr-30">
                  <CategoryGrid show={10} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default AllCategoryList
