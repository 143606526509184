import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { getPages } from "store/actions"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import { useWindowSize } from "react-use"
import InfiniteScroll from "react-infinite-scroll-component"
import useCloudinary from "components/hooks/useCloudinaryImage"
import pageImg from "assets/imgs/landing/pagesDefualt.svg"
import { useRemoveSpace } from "components/hooks/useRemoveSpace"
import { LogoSm, MainLogo } from "assets/imgs/logos"

const HomePagesGrid = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()

  const { homePages, loading } = useSelector(state => ({
    homePages: state.LayoutPages.pages,
    loading: state.LayoutPages.loading,
  }))

  const [paginatedProductPages, setPaginatedProductPages] = useState([])

  const handleSingleViewClick = catName => {
    const removeSpace = catName?.replace(/ /g, "-")

    history.push({
      pathname: "/products",
      query: {
        ...location.query,
        cat: removeSpace,
        isProduct: false,
      },
    })
  }
  //pagination
  const limit = 4
  const allpages = homePages?.total / limit
  let [page, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (page < allpages) {
      setPages(page + 1)
    }
  }

  useEffect(() => {
    if (homePages?.pages?.length >= 1) {
      setPaginatedProductPages([...paginatedProductPages, ...homePages?.pages])
    }
  }, [homePages])

  const ids = paginatedProductPages?.map(o => o._id)

  const filteredFinalCat = paginatedProductPages.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  useEffect(() => {
    dispatch(getPages(page, limit))
  }, [page, limit])

  return (
    <InfiniteScroll
      dataLength={filteredFinalCat?.length ?? 0}
      next={HandlefetchNextPage}
      hasMore={true}
      loader={loading && <ProductListLoader key={0} className="isMoblieCate" />}
    >
      <div className="row">
        {filteredFinalCat?.length >= 1 ? (
          <>
            {filteredFinalCat?.map((item, i) => (
              <Link
                to={`/pages/${useRemoveSpace(item?.title)}`}
                className={
                  "mobileCat pages col-lg-3 col-md-4 col-6 text-center hover-up mb-30 animated"
                }
                key={i}
              >
                <div className="post-thumb">
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      handleSingleViewClick(item?.primaryLang?.name)
                    }
                  >
                    <img
                      className="border-radius-15"
                      src={
                        item?.product?.images?.length
                          ? useCloudinary(
                              item?.images?.length >= 1
                                ? item?.images[0]?.url
                                : item?.product &&
                                    item?.product?.images[0]?.url,
                              "w_500"
                            )
                          : MainLogo
                      }
                      title="toysinside-category"
                      loading="eager"
                      alt="toysinside-category"
                    />
                  </span>
                </div>
                <div className="entry-content-2">
                  <h6 className="mb-10 font-md">
                    <Link to={`#!`}>
                      <span className="entry-meta text-black">
                        {item?.title}
                      </span>
                    </Link>
                  </h6>

                  {width >= 480 && (
                    <div className="entry-meta font-xs color-grey mt-10 pb-10">
                      <div>
                        <span className="post-on mr-10">
                          {item?.primaryLang?.description}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            ))}
          </>
        ) : (
          <div className="d-flex flex-column text-center algin-iems-cener justify-content-center">
            <img src={pageImg} height={300} />
            <p className="mt-4">No Pages Found!</p>
          </div>
        )}
      </div>
    </InfiniteScroll>
  )
}

export default HomePagesGrid

HomePagesGrid.propTypes = {
  show: PropTypes.any,
  wide: PropTypes.any,
}
