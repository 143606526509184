import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_BY_CATEGORY,
  GET_PRODUCT_BY_CATEGORY_SUCCESS,
  GET_PRODUCT_BY_CATEGORY_FAIL,
  GET_RELATED_PRODUCT,
  GET_RELATED_PRODUCT_SUCCESS,
  GET_RELATED_PRODUCT_FAIL,
} from "./actionTypes"

export const getProducts = options => ({
  type: GET_PRODUCTS,
  payload: options,
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})
export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})

//filter
export const getProductsByCategory = options => ({
  type: GET_PRODUCT_BY_CATEGORY,
  payload: options,
})

export const getProductsByCategorySuccess = products => ({
  type: GET_PRODUCT_BY_CATEGORY_SUCCESS,
  payload: products,
})
export const getProductsByCategoryFail = error => ({
  type: GET_PRODUCT_BY_CATEGORY_FAIL,
  payload: error,
})

export const getProductDetail = productName => ({
  type: GET_PRODUCT_DETAIL,
  productName,
})

export const getProductDetailSuccess = productDetail => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: productDetail,
})

export const getProductDetailFail = error => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
})

//related product
export const getRelatedProducts = productName => ({
  type: GET_RELATED_PRODUCT,
  payload: productName,
})

export const getRelatedProductsSuccess = products => ({
  type: GET_RELATED_PRODUCT_SUCCESS,
  payload: products,
})
export const getRelatedProductsFail = error => ({
  type: GET_RELATED_PRODUCT_FAIL,
  payload: error,
})
