import React, { useEffect } from "react"
import Layout from "components/layout/Layout"
import { useDispatch, useSelector } from "react-redux"
import { getBlogDetails } from "store/actions"
import { useLocation } from "react-router-dom"
import { Markup } from "interweave"
import moment from "moment"
import SwiperCore, { Navigation, Keyboard, Mousewheel, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"

SwiperCore.use([Navigation, Keyboard, Mousewheel, Autoplay])

import { useWindowSize } from "react-use"
import useCloudinary from "constants/UseCloudinaryImage"

function SingleBlog() {
  const dispatch = useDispatch()
  const location = useLocation()

  const { blogDetails, loading } = useSelector(state => ({
    blogDetails: state.Blogs.blogDetails,
    loading: state.Blogs.loading,
  }))

  useEffect(() => {
    if (location?.state?.blogId) {
      dispatch(getBlogDetails(location?.state?.blogId))
    }
  }, [dispatch, location?.state?.blogId])

  const { width } = useWindowSize()

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 1.2
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 2.5
    } else return 3
  }

  return (
    <>
      {/* <Markup content={details?.description} /> */}

      <Layout parent="Home" sub="Blog" subChild="Blog Details">
        <section className="mt-50 mb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="single-page pt-50 pr-30">
                  <div className="single-header style-2">
                    <div className="row">
                      <div className="col-xl-10 col-lg-12 m-auto">
                        <h6 className="mb-10 primary-color">Blogs</h6>
                        <h2 className="mb-10">{blogDetails?.title}</h2>
                        <div className="single-header-meta">
                          <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                            {/* <Link href="#">
                              <a className="author-avatar">
                                <img
                                  className="img-circle"
                                  src="/assets/imgs/blog/author-1.png"
                                  alt=""
                                />
                              </a>
                            </Link>
                            <span className="post-by">
                              By{" "}
                              <Link href="#">
                                <a>Sugar Rosie</a>
                              </Link>
                            </span> */}
                            <span className="post-on has-dot">
                              {moment(blogDetails?.createdAt).format("LLLL")}
                            </span>
                            <span className="time-reading has-dot">
                              8 mins read
                            </span>
                          </div>
                          {/* <div className="social-icons single-share">
                            <ul className="text-grey-5 d-inline-block">
                              <li className="mr-5">
                                <Link href="#">
                                  <img
                                    src={
                                      "/assets/imgs/theme/icons/icon-bookmark.svg"
                                    }
                                    alt=""
                                  />
                                </Link>
                              </li>
                              <li>
                                <Link href="#">
                                  <a>
                                    <img
                                      src="/assets/imgs/theme/icons/icon-heart-2.svg"
                                      alt=""
                                    />
                                  </a>
                                </Link>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <figure className="single-thumbnail">
                    <img
                      src={blogDetails?.images && blogDetails?.images[0]?.url}
                      alt=""
                      style={{
                        maxHeight: 500,
                        width: "100%",
                      }}
                    />
                  </figure>
                  <div className="single-content">
                    <div className="row">
                      <div className="col-xl-10 col-lg-12 m-auto">
                        <p className="single-excerpt sm:font-size-16">
                          {blogDetails?.description}
                        </p>
                        <Markup
                          className="blog_details_content"
                          content={blogDetails?.content}
                        />
                        <Swiper
                          spaceBetween={20}
                          slidesPerView={handleWindowSizeVsSliderPreview()}
                          grid={{
                            rows: 2,
                          }}
                          keyboard={{ enabled: true }}
                          direction="horizontal"
                          // mousewheel={true}
                          navigation={{
                            prevEl: ".custom_prev_f",
                            nextEl: ".custom_next_f",
                          }}
                          autoplay={true}
                          speed={400}
                          loop={true}
                          className="text-center"
                          breakpoints={{
                            480: {
                              slidesPerView: 1.2,
                            },
                            640: {
                              slidesPerView: 2.5,
                            },
                            768: {
                              slidesPerView: 2.5,
                            },
                            1024: {
                              slidesPerView: 3,
                            },
                          }}
                        >
                          {blogDetails?.images?.length > 1 &&
                            blogDetails?.images?.map((item, i) => (
                              <SwiperSlide key={i} className="h:350px">
                                <div className="">
                                  <div
                                    className="wow animate__animated animate__fadeInUp h:350px"
                                    data-wow-delay="0"
                                  >
                                    <img
                                      src={useCloudinary(item?.url, "w_800")}
                                      alt=""
                                      className="h:300px w-100"
                                    />
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
                            <span className="slider-btn slider-prev slick-arrow custom_prev_f">
                              <i className="fi-rs-arrow-small-left"></i>
                            </span>
                            <span className="slider-btn slider-next slick-arrow custom_next_f">
                              <i className="fi-rs-arrow-small-right"></i>
                            </span>
                          </div>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default SingleBlog
