import React, { useEffect } from "react"
import ProductDetails from "../../components/ecommerce/productCards/ProductDetails"
import Layout from "../../components/layout/Layout"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  getProductDetail,
  getRelatedProducts,
  getSinglePage,
} from "store/actions"
import useLangSwitch from "constants/LanguageSwicth"
import ProductLoader from "components/Common/loaders/ProductDetailsLoader"
import Chat from "components/Common/chat"
import LandingPage from "pages/LandingPages"
import { useWindowScroll } from "react-use"
import useMetaHandler from "components/hooks/useMetaHandler"
import MetaData from "util/MetaData"

const ProductSingle = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const { y } = useWindowScroll()

  const { productDetail, loading } = useSelector(state => ({
    productDetail: state.Products.productDetail.product,
    loading: state.Products.loading,
  }))

  const removeArrow = params?.name?.replace(/-/g, " ")

  useEffect(() => {
    dispatch(getProductDetail(removeArrow))
    dispatch(getRelatedProducts(removeArrow))
  }, [dispatch, removeArrow])

  const language = useLangSwitch(
    productDetail?.primaryLang,
    productDetail?.secondaryLang
  )

  const {
    metaDescription,
    metaKeyword,
    metaTitle,
    canonicalLink,
    metaProduct,
  } = useMetaHandler(language?.name, language?.description, productDetail)

  useEffect(() => {
    if (productDetail?.page) {
      dispatch(getSinglePage(productDetail?.page))
    }
  }, [productDetail?.page])

  return (
    <>
      <MetaData
        content={language?.name}
        title={metaTitle}
        description={metaDescription}
        keyword={metaKeyword}
        canonicalLink={canonicalLink}
        ogImage={productDetail?.images[0]?.url}
        product={metaProduct}
      />
      <Layout
        parent="Home"
        sub={productDetail?.categories[0]?.primaryLang?.name}
        subChild={language?.name}
      >
        <div className="container sm:p-0">
          {loading ? (
            <ProductLoader />
          ) : (
            <ProductDetails product={productDetail} />
          )}
          <Chat />
        </div>
        {productDetail?.isPageAttached === true ? (
          <LandingPage isAttachedPage={true} />
        ) : (
          ""
        )}
      </Layout>
    </>
  )
}
export default ProductSingle

ProductSingle.propTypes = {
  product: PropTypes.any,
}
