import React from "react"
import useLangSwitch from "constants/LanguageSwicth"
import useCartActions from "constants/useCartAction"
import usePrice from "constants/UsePrice"
import PropTypes from "prop-types"

import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { IoCloseOutline } from "react-icons/io5"
import useCloudinary from "constants/UseCloudinaryImage"

function CheckoutItem({ item }) {
  const product = item?.product

  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.Cart.addOrRemoveLoad,
  }))

  const { currencyCode, returnText } = usePrice(product)

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const increaseQuantity = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "add",
    }
    useCartActions(options, dispatch, item?.product)
  }

  const decreaseQuantity = item => {
    if (item?.quantity <= 1) {
      return
    } else {
      const options = {
        product: item?.product?._id,
        varient: item?.varient,
        operation: "substract",
      }
      useCartActions(options, dispatch, item?.product)
    }
  }

  const currentVarient = item?.product?.varients?.filter(
    i => i._id === item?.varient
  )[0]

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient?.stock <= 0) {
        return "Out of Stock"
      } else if (item?.quantity > currentVarient?.stock) {
        return "Only " + currentVarient?.stock + " left"
      }
    } else return ""
  }

  const removeSpace = language?.name?.replace(/ /g, "-")

  const deleteFromCart = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "substract",
      quantity: item?.quantity ?? 1,
    }
    useCartActions(options, dispatch, item?.product)
  }

  return (
    <>
      <tr>
        <td className="image product-thumbnail ">
          <Link to={"#"} className="position-relative fade show ">
            <img
              src={useCloudinary(product.images[0]?.url, "h_400,w_400")}
              alt="#"
            />

            <IoCloseOutline
              size={"1.5rem"}
              onClick={() => deleteFromCart(item)}
              className="removeFromCart"
            />
          </Link>
        </td>
        <td>
          <div>
            <h6 className="mb-3">
              <Link className="text-black" to={`/products/${removeSpace}`}>
                {language?.name}
                <br />
                {item?.varientValue && (
                  <span className="white-space-nowrap">
                    ({item?.varientValue}&nbsp;
                    {item?.varientUnit})
                  </span>
                )}
              </Link>
            </h6>{" "}
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="text-muted">
                <span className="text-brand white-space-nowrap">
                  {currencyCode} {item?.price}{" "}
                  <span className="text-gray">x {item?.quantity}</span>
                </span>
                <div className="d-flex  m-0 py-2">
                  {loading ? (
                    <span className="line_load bg-lightgreen"></span>
                  ) : (
                    <>
                      <AiOutlineMinusCircle
                        className="text-brand cursor-pointer hover-up"
                        size={"1.3rem"}
                        onClick={() => decreaseQuantity(item)}
                      />
                      <AiOutlinePlusCircle
                        className="mx-1 text-brand cursor-pointer hover-up"
                        size={"1.3rem"}
                        onClick={() => increaseQuantity(item)}
                      />
                    </>
                  )}
                </div>
              </h6>
              <h4 className="text-brand white-space-nowrap">
                {currencyCode} {item.quantity * item?.price}
              </h4>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
export default CheckoutItem

CheckoutItem.propTypes = {
  item: PropTypes.any,
}
