import ThreeDotsLoading from "components/Common/loaders/ThreeDotLoading"
import SearchListVertical from "components/elements/SearchListVertical"
import { useOutsideAlerter } from "constants/useOutSideClick"
import { debounce, map } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { getProducts, getProductsByCategory } from "store/actions"
import PropTypes from "prop-types"
import { MdKeyboardArrowDown } from "react-icons/md"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const Search = ({ isMobile, isSingle }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const searchRef = useRef(null)
  const location = useLocation()

  const { allCategories, loading, products } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Products.loading,
    products: state.Products.products,
  }))

  const [searchTerm, setSearchTerm] = useState("")
  const [finalInput, setFinalInput] = useState("")
  const [selectedCat, setSelectedCat] = useState("")

  const [showList, setsHowList] = useState(false)

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(searchRef, setOutSide)

  useEffect(() => {
    const options = {
      searchText: finalInput,
      page: "",
      custom: "all",
      sort: "",
      limit: "",
    }

    const CatOptions = {
      ...options,
      custom: selectedCat,
    }

    if (selectedCat && selectedCat !== "All") {
      dispatch(getProductsByCategory(CatOptions))
    } else {
      if (finalInput) {
        dispatch(getProducts(options))
      }
    }
  }, [finalInput, selectedCat])

  const handleInput = e => {
    const finalprodName = products?.products[0]?.primaryLang?.name

    const removeSpace = finalprodName?.replace(/ /g, "-")

    if (e.key === "Enter") {
      e.preventDefault()
      history.push(`/products/${removeSpace}`)
    }
  }

  const printValue = debounce(value => setFinalInput(value), 500)

  const handleChangeSearch = value => {
    printValue(value)
    setSearchTerm(value)
    setsHowList(true)
  }

  useEffect(() => {
    if (outSide === true) {
      setsHowList(false)
      setOutSide(false)
    }
  }, [outSide])

  useEffect(() => {
    if (finalInput?.length >= 1) {
      history.push({
        pathname: location?.pathname,
        query: {
          ...location.query,
          isSearch: true,
          search: finalInput,
        },
      })
    } else {
      // history.push({
      //   pathname: location?.pathname,
      //   query: {
      //     ...location.query,
      //     isSearch: false,
      //     search: finalInput,
      //   },
      // })
    }
  }, [finalInput])

  const [hideList, setHideList] = useState(false)

  useEffect(() => {
    if (
      location?.pathname === "/shop-list" ||
      location?.pathname === "/collection" ||
      location?.pathname === "/category"
    ) {
      setHideList(true)
    } else {
      setHideList(false)
    }
  }, [location?.pathname])

  return (
    <>
      <form onClick={() => isMobile && history.push("/search")}>
        {!isMobile && (
          <div className="select-wrapper px-1">
            <select
              className="select-active"
              onChange={e => setSelectedCat(e.target.value)}
              style={{
                minWidth: "fit-content",
                width: selectedCat?.length
                  ? `${selectedCat?.length * 12}px`
                  : "160px",
                paddingRight: 0,
              }}
            >
              <option>All Categories</option>
              {map(allCategories, (cat, key) => (
                <option className="text-dark" key={key}>
                  {cat.primaryLang.name}
                </option>
              ))}
            </select>
            {/* <MdKeyboardArrowDown className="search-icon-new" /> */}
          </div>
        )}
        <input
          value={searchTerm}
          onKeyDown={handleInput}
          onChange={e => handleChangeSearch(e.target.value)}
          type="text"
          placeholder="Search for products"
          style={{ marginLeft: 0 }}
          autoFocus={isSingle && "autofocus"}
          className="text-white"
        />
      </form>
      {hideList === false && (
        <>
          {searchTerm && showList && (
            <div
              className={`zIndex-full position-absolute bg-white boxshadow-4 border-radius-15 left-0 col-md-4-5 col-lg-4-5 ${
                isMobile ? "myMobileSearchList" : ""
              }`}
              ref={searchRef}
              style={{
                top: "50px",
                paddingBottom: "15px",
              }}
            >
              {loading ? (
                <ThreeDotsLoading />
              ) : (
                <>
                  {products?.products?.length <= 0 ? (
                    <>
                      <div className="p-3 d-flex align-items-center justify-content-between">
                        <div>
                          <p className="font-size-14">{`We couldn't find any products! "${searchTerm}"`}</p>
                          <span>{`Don't worry, you can explore our extensive collections.`}</span>
                        </div>
                        <Link
                          to="/shop-list"
                          className="btn bg-warning mx-2"
                          style={{ opacity: ".8" }}
                        >
                          Explore
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      {map(products?.products?.slice(0, 5), (product, key) => (
                        <SearchListVertical
                          setsHowList={setsHowList}
                          product={product}
                          key={key}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Search
Search.propTypes = {
  isMobile: PropTypes.bool,
  isSingle: PropTypes.bool,
}
