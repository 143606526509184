import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CATEGORIES,
  GET_CATEGORY_NESTEDLOOP,
  GET_SINGLE_CATEGORY,
} from "./actionTypes"
import {
  getAllCategoriesSuccess,
  getCategoriesFail,
  getMainCategoriesSuccess,
  getMainGroupedCategoriesSuccess,
  getNestedCategoryFail,
  getNestedCategorySuccess,
  getSingleCategoryFail,
  getSingleCategorySuccess,
  getSubCategoriesSuccess,
} from "./actions"

import { get } from "helpers/api_methods"

function getCategoryApi({ page, type }) {
  return get(`/category/user/custom/${type}?page=${page ? page : 1}`)
}
function getSingleCategoryApi({}) {
  return ""
}

function getNestedCategoryApi({ catId, limit }) {
  return get(`/category/user/product/all/${catId}?limit=${limit ? limit : ""}`)
}

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getCategoryApi, payload)
    if (payload.type === "all") {
      yield put(getAllCategoriesSuccess(response))
    }
    if (payload.type === "main") {
      yield put(getMainCategoriesSuccess(response))
    }
    if (payload.type === "sub") {
      yield put(getSubCategoriesSuccess(response))
    }
    if (payload.type === "grouped-main") {
      yield put(getMainGroupedCategoriesSuccess(response))
    }
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}
function* fetchSingleCategory({ payload }) {
  const response = yield call(getSingleCategoryApi, payload)
  yield put(getSingleCategorySuccess(response))
  try {
  } catch (error) {
    yield put(getSingleCategoryFail(error))
  }
}

function* fetchNestedCategory({ payload }) {
  const response = yield call(getNestedCategoryApi, payload)
  yield put(getNestedCategorySuccess(response))
  try {
  } catch (error) {
    yield put(getNestedCategoryFail(error))
  }
}

function* classificationSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_SINGLE_CATEGORY, fetchSingleCategory)
  yield takeEvery(GET_CATEGORY_NESTEDLOOP, fetchNestedCategory)
}

export default classificationSaga
