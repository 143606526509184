import React, { useEffect } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import BannerLoader from 'components/Common/loaders/BannerLoader';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import MyRating from 'components/productUtil/Rating';
import PropTypes from 'prop-types';
import useCloudinary from 'components/hooks/useCloudinaryImage';
import { useCustomDevice } from 'components/hooks/useCustomDevice';
import useWindow from 'components/hooks/useWindow';
import LazyLoad from 'react-lazyload';

SwiperCore.use([Navigation, Pagination]);

const TestimonialCard = ({ data }) => {
  const { isMobile } = useWindow();

  const { loading } = useSelector((state) => ({
    loading: state.Banner.loading,
  }));

  const web = (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      freeMode={true}
      pagination={false}
      slidesPerView={2.5}
      initialSlide={1}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 2.3,
        },
        1200: {
          slidesPerView: 2.5,
        },
      }}
    >
      {loading ? (
        <SwiperSlide>
          <BannerLoader />
        </SwiperSlide>
      ) : (
        <>
          {map(
            data?.testimonials,
            (ban, key) => (
              console.log(ban?.rating),
              (
                <SwiperSlide key={key}>
                  <div className="review-slide">
                    <div className="d-flex flex-column align-items-center m-auto mb-4 justify-content-center w-fit">
                      {ban?.image?.url && (
                        <LazyLoad height={70}>
                          <img
                            className="rounded-circle mb-2"
                            src={useCloudinary(
                              ban?.image?.url ?? '',
                              isMobile ? 'w_300' : 'w_800,q_70',
                            )}
                            loading={'lazy'}
                            alt="google icon"
                            width={70}
                            height={70}
                          />
                        </LazyLoad>
                      )}
                      <MyRating
                        initialRating={ban?.rating}
                        quiet={true}
                        readonly={true}
                      />
                    </div>
                    <p className="mb-3 text-center">
                      {ban?.message?.length >= 200
                        ? ban?.message?.slice(0, 200) + '...'
                        : ban?.message}
                    </p>
                    <h4 className="text-center w-fit m-auto">{ban?.name}</h4>
                  </div>
                </SwiperSlide>
              )
            ),
          )}
        </>
      )}
    </Swiper>
  );

  const mobile = web;

  return useCustomDevice(data?.platform, mobile, web);
};

export default TestimonialCard;

TestimonialCard.propTypes = {
  data: PropTypes.any,
};
