import React from 'react';
import el1 from 'assets/imgs/landing/Elements/Ellipse1.png';
import el2 from 'assets/imgs/landing/Elements/Ellipse2.png';
import el3 from 'assets/imgs/landing/Elements/Ellipse3.png';
import el4 from 'assets/imgs/landing/Elements/Ellipse4.png';
import useWindow from 'components/hooks/useWindow';
import PropTypes from 'prop-types';
import { useCustomDevice } from 'components/hooks/useCustomDevice';
import LazyLoad from 'react-lazyload';
import YouTube from 'react-youtube';

function Video({ data }) {
  const { isMobile } = useWindow();

  const opts = {
    width: isMobile ? '310px' : '800px',
    height: isMobile ? '300px' : '450px',
    playerVars: {
      autoplay: 1,
    },
  };

  const web = (
    <div className="container pb-4  sm:p-0">
      <div className="mx-5 sm:mx-0">
        <div className="video_banner">
          <h4 className="px-3 font-w-600">Let’s Check it out!</h4>
          <div className="react-player d-flex-center-center">
            <LazyLoad height={170}>
              <img
                src={el1}
                height={170}
                width={170}
                className="el el1"
                alt="elements"
                loading="lazy"
              />
            </LazyLoad>
            <LazyLoad height={170}>
              <img
                src={el2}
                height={148}
                width={148}
                className="el el2"
                alt="elements"
                loading="lazy"
              />
            </LazyLoad>
            <LazyLoad height={170}>
              <img
                src={el3}
                height={52}
                width={52}
                className="el el3"
                alt="elements"
                loading="lazy"
              />
            </LazyLoad>
            <LazyLoad height={170}>
              <img
                src={el4}
                height={35}
                width={35}
                className="el el4"
                alt="elements"
                loading="lazy"
              />
            </LazyLoad>

            <div className="react-player-video">
              <YouTube
                videoId={data?.videoId}
                id={data?.videoId}
                opts={opts}
                className="d-flex"
                iframeClassName={isMobile && 'w-100'}
                // className={string}
                // style={object}
                // title={string}
                // loading={string}
                // onReady={func}
                // onPlay={func}
                // onPause={func}
                // onEnd={func}
                // onError={func}
                // onStateChange={func}
                // onPlaybackRateChange={func}
                // onPlaybackQualityChange={func}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const mobile = web;

  return useCustomDevice(data?.platform, mobile, web);
}

export default Video;

Video.propTypes = {
  data: PropTypes.any,
};
