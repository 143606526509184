import React, { useCallback, useState } from "react"
import { connect, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import useLangSwitch from "constants/LanguageSwicth"
import usePrice from "constants/UsePrice"
import { Link } from "react-router-dom"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import { defualtProd } from "assets/imgs/logos"
import useWishlistAction from "constants/useWishlistAction"
import useCloudinary from "constants/UseCloudinaryImage"

const ProductCardTwo = ({ product }) => {
  const dispatch = useDispatch()

  const user_favourite =
    storage.get("user_favourite") !== null && storage.get("user_favourite")

  const handleWishlist = productId => {
    useWishlistAction(dispatch, productId)
  }

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, currentVarient } = usePrice(product)
  // const { currencyCode, oldPrice, price, stock, currentVarient } = useCallback(
  //   usePrice(product),
  //   [product]
  // )

  const removeSpace = language?.name?.replace(/ /g, "-")
  const discountPercentage = Math.floor(((oldPrice - price) / price) * 100)

  return (
    <>
      <div className="product-cart-wrap border-none">
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom m-auto ">
            <Link to={`/products/${removeSpace}`}>
              <img
                className="default-img"
                src={`${
                  useCloudinary(product.images[0]?.url, "w_500") ?? defualtProd
                }`}
                alt=""
              />
              <img
                className="hover-img"
                src={
                  useCloudinary(
                    product.images[1]?.url
                      ? product.images[1]?.url
                      : product.images[0]?.url,
                    "w_500"
                  ) ?? defualtProd
                }
                alt=""
              />
            </Link>
          </div>
          <div className="product-action-1">
            {/* <Link
              to={`/products/${removeSpace}`}
              aria-label="Quick view"
              className="action-btn hover-up"
              data-bs-toggle="modal"
            >
              <i className="fi-rs-eye"></i>
            </Link> */}
            <a
              aria-label="Add To Wishlist"
              className="action-btn bg-white"
              onClick={e => handleWishlist(product?._id)}
            >
              {user_favourite?.length >= 1 &&
              user_favourite?.includes(product?._id) ? (
                <FaHeart
                  className="text-danger"
                  style={{ marginTop: "-6px" }}
                />
              ) : (
                <FaRegHeart
                  className="text-black"
                  style={{ marginTop: "-6px" }}
                />
              )}
            </a>
          </div>
        </div>
        <div className="product-content-wrap p-10 pt-0">
          <h2 className="text-size-12 mt-1">
            <Link to={`/products/${removeSpace}`}>
              <span>
                {language?.name?.slice(0, 32)}
                <span style={{ whiteSpace: "nowrap" }}>
                  &nbsp;({currentVarient})
                </span>
              </span>
            </Link>
          </h2>

          <div className="product-price mt-0 p-0">
            {oldPrice && (
              <>
                <span className="old-price font-normal">{oldPrice}</span>
                &nbsp;
              </>
            )}

            <span
              className="font-head text-gray"
              style={{ margin: "0 5px 0 5px" }}
            >
              {currencyCode}
              {price}&nbsp;
            </span>
            {(product?.discountPercentage > 0 || discountPercentage > 0) && (
              <span className="price-per">
                {product?.discountPercentage ?? discountPercentage}% off
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardTwo)

ProductCardTwo.propTypes = {
  product: PropTypes.any,
}
