import React from "react"
import PropTypes from "prop-types"

import empty from "assets/imgs/shop/cart-empty.gif"
import { Link } from "react-router-dom"

function EmptyCartSvg({ style }) {
  return (
    <>
      <div
        className="d-flex align-items-center flex-revers-col emptyCart"
        style={style}
      >
        <div>
          {style?.isDes ? (
            <>
              <h4 className="text-brand cartempty">Your cart is empty!</h4>
              <p className="text-black cartemptySub">
                “Explore our wide selection and find something you like”
              </p>
            </>
          ) : (
            <p className="text-brand">Your cart is empty!</p>
          )}
          <Link to="/shop-list" className="m-auto w-fit">
            <span className="btn btn-xs submit-auto-width font-smaller hover-up mt-30">
              <i className="fi-rs-home mr-5"></i> Back To Shopping
            </span>
          </Link>
        </div>
        <img src={empty} alt="" className="h-100" />
      </div>
    </>
  )
}

export default EmptyCartSvg

EmptyCartSvg.propTypes = {
  style: PropTypes.any,
}
