import React from "react"
import { MainLogo } from "assets/imgs/logos"
import { Link, useLocation } from "react-router-dom"
import { Col, Row } from "reactstrap"
import facebook from "assets/imgs/theme/icons/icon-facebook-white.svg"
import instagram from "assets/imgs/theme/icons/icon-instagram-white.svg"
import { FaLinkedinIn, FaTiktok, FaWhatsapp } from "react-icons/fa"
import { RiSnapchatLine } from "react-icons/ri"
import { useSelector } from "react-redux"

import LazyLoad from "react-lazyload"

function Footer() {
  const location = useLocation()

  const { socialLinks, company, loadingProd } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
    socialLinks: state.SiteSettings.siteSettings.socialLinks,
    loadingProd: state.Products.loading,
  }))
  return (
    <>
      {/* {!loadingProd && location.pathname === "/" && (
        <section className="newsletter mb-15  wow animate__animated animate__fadeIn pt-4">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6">
                <div className="newsletter-content">
                  <LazyLoad height={200}>
                    <img
                      src={bannerBottom2}
                      alt="newsletter"
                      title="newsletter"
                      loading="eager"
                      className="border-radious-small max-h-360 bannerFooterImg"
                    />
                  </LazyLoad>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="newsletter-content">
                  <LazyLoad height={200}>
                    <img
                      src={bannerBottom}
                      alt="newsletter"
                      title="newsletter"
                      loading="eager"
                      className="border-radious-small max-h-360 bannerFooterImg"
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}
      <div className="footer">
        <Row className="m-0">
          <Col lg={8} className="sm:p-0">
            <div className="d-flex align-items-center logos">
              <Link to={"/"}>
                <LazyLoad>
                  <img
                    src={MainLogo}
                    alt="footer-logo"
                    className="logo-footer"
                    loading="lazy"
                    height={70}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </LazyLoad>
              </Link>
              <div className="footerAddress sm:m-0">
                <p>Address : {company?.address?.addressLine1}</p>
                <p> {company?.address?.addressLine2}</p>
                <p>Call Us : {company?.contactDetails?.mobile}</p>
                <p> Email : {company?.contactDetails?.email}</p>
                <div className="mobile-social-icon d-flex align-items-center">
                  {socialLinks?.facebook && (
                    <a
                      href={socialLinks?.facebook}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={facebook}
                        alt="facebook"
                        title="facebook"
                        loading="eager"
                      />
                    </a>
                  )}
                  {socialLinks?.instagram && (
                    <a
                      href={socialLinks?.instagram}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={instagram}
                        alt="instagram"
                        title="instagram"
                        loading="eager"
                      />
                    </a>
                  )}

                  {socialLinks?.linkedin && (
                    <a
                      href={socialLinks?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaLinkedinIn color="white" />
                    </a>
                  )}

                  {socialLinks?.whatsApp && (
                    <a
                      href={socialLinks?.whatsApp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaWhatsapp color="white" />
                    </a>
                  )}
                  {socialLinks?.snapchat && (
                    <a
                      href={socialLinks?.snapchat}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <RiSnapchatLine color="white" />
                    </a>
                  )}
                  {socialLinks?.tiktok && (
                    <a
                      href={socialLinks?.tiktok}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaTiktok color="white" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="p-0">
            <div className="d-flex">
              <div
                className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                <h3 className="widget-title h5">Company</h3>
                <ul className="footer-list  mb-sm-5 mb-md-0">
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms</Link>
                  </li>
                  <li>
                    <Link to="/return-policy">Return Policy</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div
                className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                <h3 className="widget-title h5">Account</h3>
                <ul className="footer-list  mb-sm-5 mb-md-0">
                  <li>
                    <Link to="/user-account">Account</Link>
                  </li>
                  <li>
                    <Link to="/cart">View Cart</Link>
                  </li>
                  <li>
                    <Link to="/wishlist">My Wishlist</Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/user-account",
                        state: { index: 2 },
                      }}
                    >
                      My Order
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Footer
