import React from "react"
import { BsSearch } from "react-icons/bs"
import {
  HiOutlineHome,
  HiOutlineShoppingCart,
  HiOutlineUserCircle,
  HiOutlineViewGrid,
} from "react-icons/hi"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"

function MobileNav() {
  const location = useLocation()
  const { cartItems, localCart, isLoginPopup, cartNoti } = useSelector(
    state => ({
      cartItems: state.Cart.cartItems,
      localCart: state.Cart.localCart,
      isLoginPopup: state.Layout.isLoginPopup,
      cartNoti: state.Layout.cartNoti,
    })
  )

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  return (
    <>
      {location.pathname === "/checkout" ? (
        <></>
      ) : (
        <div
          className="MobileNav"
          style={{
            boxShadow:
              isLoginPopup || cartNoti
                ? ""
                : "rgb(149 157 165 / 20%) 0px -5px 24px",
          }}
        >
          <Link to={"/"} className="text-gray mobile_nav_item">
            <HiOutlineHome />
            <span>Home</span>
          </Link>
          <Link to={"/search"} className="text-gray mobile_nav_item">
            <BsSearch style={{ padding: "1.5px" }} />
            <span>Search</span>
          </Link>
          <Link
            to={{
              pathname: "/shop-list",
              query: {
                prod: "all",
              },
            }}
            className="text-gray mobile_nav_item"
          >
            <HiOutlineViewGrid />
            <span>Products</span>
          </Link>

          <Link to={"/user-account"} className="text-gray mobile_nav_item">
            <HiOutlineUserCircle />
            <span>Account</span>
          </Link>
          <Link
            to={"/cart"}
            className="text-gray mobile_nav_item position-relative"
          >
            <HiOutlineShoppingCart />
            <span>Cart</span>
            {handleCartItem()?.length >= 1 && (
              <span
                className="pro-count text-white navIcon2 bg-darkGreen px-1"
                style={{ borderRadius: "50%" }}
              >
                {handleCartItem()?.length}
              </span>
            )}
          </Link>
        </div>
      )}
    </>
  )
}

export default MobileNav
