import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { getCategories } from "store/actions"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import { useWindowSize } from "react-use"
import InfiniteScroll from "react-infinite-scroll-component"
import useCloudinary from "constants/UseCloudinaryImage"

const CategoryGrid = ({ show, wide }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  const [paginatedCat, setPaginatedCat] = useState([])

  const handleSingleViewClick = catName => {
    const removeSpace = catName?.replace(/ /g, "-")

    history.push({
      pathname: "/shop-list",
      query: {
        ...location.query,
        cat: removeSpace,
        isProduct: false,
      },
    })
  }

  //pagination
  const limit = 10
  const allpages = allCategories?.length / limit
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }

  useEffect(() => {
    setPaginatedCat([...paginatedCat, ...allCategories])
  }, [allCategories])

  const ids = paginatedCat?.map(o => o._id)

  const filteredFinalCat = paginatedCat.filter(
    ({ _id }, index) => !ids.includes(_id, index + 1)
  )

  useEffect(() => {
    dispatch(getCategories(pages, "all", limit, ""))
  }, [dispatch, pages])

  return (
    <InfiniteScroll
      dataLength={filteredFinalCat?.length ?? 0}
      next={HandlefetchNextPage}
      hasMore={true}
      loader={loading && <ProductListLoader key={0} className="isMoblieCate" />}
    >
      <div className="row">
        <>
          {filteredFinalCat?.map((item, i) => (
            <article
              className={
                wide
                  ? "mobileCat col-xl-2 col-lg-2 col-md-4  col-sm-3 col-3 text-center hover-up mb-30 animated"
                  : "mobileCat col-xl-2 col-lg-2 col-md-4  col-sm-3 col-3 text-center hover-up mb-30 animated"
              }
              key={i}
            >


              <div className="post-thumb">
                <span
                  className="cursor-pointer"
                  onClick={() => handleSingleViewClick(item?.primaryLang?.name)}
                >
                  <img
                    className="border-radius-15"
                    src={
                      item?.logoImages[0]?.url
                        ? useCloudinary(item?.logoImages[0]?.url, "w_200")
                        : category1
                    }
                    alt=""
                    // style={{ for web
                    //   height: "100px",
                    //   width: "150px",
                    //   objectFit: "contain",
                    //   padding: "20px ",
                    //   paddingBottom: "0",
                    // }}
                  />
                </span>
              </div>
              <div className="entry-content-2">
                <h6 className="mb-10 font-sm">
                  <Link to={`#!`}>
                    <span className="entry-meta text-black">
                      {item?.primaryLang?.name}
                    </span>
                  </Link>
                </h6>
                <h4 className="post-title mb-15">
                  <Link to="#!">{item?.title}</Link>
                </h4>
                {width >= 480 && (
                  <div className="entry-meta font-xs color-grey mt-10 pb-10">
                    <div>
                      <span className="post-on mr-10">
                        {item?.primaryLang?.description}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </article>
          ))}
        </>

        {/* <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
          <nav aria-label="Page navigation example">
            <Pagination
              getPaginationGroup={getPaginationGroup}
              currentPage={currentPage}
              pages={pages}
              next={next}
              prev={prev}
              handleActive={handleActive}
            />
          </nav>
        </div> */}
      </div>
    </InfiniteScroll>
  )
}

export default CategoryGrid

CategoryGrid.propTypes = {
  show: PropTypes.any,
  wide: PropTypes.any,
}
