import React from "react"
import PropTypes from "prop-types"
import WishListInnerItem from "./WishListInnerItem"

function WishlistItemWeb({ wishlist, isQuickList }) {
  return (
    <div className="table-responsive shopping-summery mx-2">
      <table className="table table-wishlist boder-none">
        <thead className="">
          <tr className="main-heading border-none">
            <th className="custome-checkbox start pl-30">Product</th>
            <th scope="col">Price</th>
            {isQuickList && <th scope="col">Quantity</th>}
            <th scope="col">{""}</th>
            {!isQuickList && (
              <th scope="col" className="end">
                Remove
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {wishlist?.map((item, i) => (
            <WishListInnerItem item={item} key={i} isQuickList={isQuickList} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WishlistItemWeb

WishlistItemWeb.propTypes = {
  wishlist: PropTypes.any,
  isQuickList: PropTypes.bool,
}
