import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { useDispatch, useSelector } from "react-redux"
import {
  bulkCart,
  getLocalCart,
  getUserCart,
  loginUserMobile,
  loginUserOtp,
} from "store/actions"
import usePrice from "constants/UsePrice"
import useLangSwitch from "constants/LanguageSwicth"
import SmallCardLoader from "components/Common/loaders/SmallCardLoader"
import { FaSpinner } from "react-icons/fa"
import CheckoutItem from "./checkoutItem"
import { useLocalCartPirces } from "constants/useCartAction"
import FilesLoad from "assets/imgs/theme/transfer-files.gif"
import { useHistory } from "react-router-dom"
import { local_token } from "helpers/api_methods"

const CheckoutLogin = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const token = localStorage.getItem(local_token)

  const {
    userCart,
    loading,
    bulkLoad,
    cartItems,
    specialMessages,
    localCart,
    userLoading,
    user,
    otp,
  } = useSelector(state => ({
    user: state.Login.user,
    userLoading: state.Login.loading,
    otp: state.Login.otp,
    userCart: state.Cart.userCart,
    loading: state.Cart.loading,
    bulkLoad: state.Cart.bulkLoad,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
  }))

  const [type, setType] = useState("login")
  const [phoneNumber, setPhoneNumber] = useState(0)

  const [otpText, setOtpText] = useState()

  const handleOtpChnages = v => {
    setOtpText(v)
  }

  useEffect(() => {
    dispatch(getUserCart())
  }, [dispatch])

  useEffect(() => {
    if (user?.userId && otpText?.length === 4) {
      dispatch(loginUserOtp({ otp: otpText, userId: user?.userId }))
    }
  }, [otpText])

  useEffect(() => {
    if (token && localCart?.length >= 1) {
      const localCartFiltered = localCart?.filter(i => i.quantity !== 0)

      const modifiedLocalCart = localCartFiltered?.map(item => ({
        product: item?.product?._id,
        varient: item?.varient,
        operation: item?.operation,
        quantity: item?.quantity,
      }))
      if (modifiedLocalCart) {
        dispatch(bulkCart(modifiedLocalCart, history))
      }
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  useEffect(() => {
    if (user?.userId) {
      setType("isOTP")
    } else {
      setType("login")
    }
  }, [user])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else {
      return localCart?.filter(i => i.quantity !== 0)
    }
  }

  const { itemTotal } = useLocalCartPirces()

  const handleMobileLogin = e => {
    e.preventDefault()
    dispatch(loginUserMobile({ phone: phoneNumber }, "login"))
  }

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Checkout">
        <section className="mt-50 mb-50 myCheckout">
          <div className="container px-5">
            <div className="row">
              <div className="col-lg-8 mb-40 header">
                <h1 className="heading-2 mb-10">Checkout</h1>
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">
                    <p className="text-brand">{language?.deliveryOffer}</p>
                    There are{" "}
                    <span className="text-brand">
                      {handleCartItem()?.length}
                    </span>{" "}
                    products in your cart
                  </h6>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 inputField">
                <div className="row mb-50">
                  <div className="mb-sm-15 mb-lg-0 mb-md-3 ">
                    <div className="panel-collapse login_form" id="loginform">
                      {bulkLoad || token ? (
                        <div className="position-relative m-auto bulkloading">
                          <p
                            className="position-absolute "
                            style={{ bottom: "45px", right: "35px" }}
                          >
                            {" "}
                            fetching your cart ...
                          </p>

                          <img
                            className="h-100 w-100"
                            src={FilesLoad}
                            alt="sync"
                          />
                        </div>
                      ) : (
                        <>
                          {type === "isOTP" ? (
                            <>
                              <div className="panel-body mt-0">
                                <div className="deal-top">
                                  <p className="mb-0">
                                    Enter OTP send to your mobile number
                                  </p>
                                  <p className="font-sm">
                                    or Re-ender your{" "}
                                    <u
                                      className="text-primary"
                                      onClick={() => setType("login")}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {" "}
                                      Mobile number{" "}
                                    </u>
                                  </p>
                                </div>
                                <div className="deal-content  detail-info">
                                  <div className="clearfix product-price-cover">
                                    <form method="post" className="">
                                      <div className="wrapper_otp ">
                                        <label className="d-flex align-items-center mb-2">
                                          {userLoading && (
                                            <FaSpinner className="mx-2 spinner" />
                                          )}
                                        </label>

                                        <div className="d-flex align-items-end">
                                          <OtpInput
                                            value={otpText}
                                            onChange={v => handleOtpChnages(v)}
                                            numInputs={4}
                                            isInputNum={true}
                                            inputStyle={"otp_input"}
                                            containerStyle={
                                              "otp_container otp_cont_2"
                                            }
                                            shouldAutoFocus={true}
                                            focusStyle={"otp_focus"}
                                          />
                                          {otp?.message ? (
                                            <p className="text-end text-info mb-0">
                                              {otp?.message}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="panel-body mt-0">
                              <h6 className="mb-0 font-md">
                                Enter your mobile number
                              </h6>
                              <p className="mb-20 font-xs">
                                By continuing, I agree to the{" "}
                                <a href="#" className="white-space-nowrap">
                                  Terms of Use
                                </a>{" "}
                                &
                                <a href="#" className="white-space-nowrap">
                                  {" "}
                                  Privacy Policy
                                </a>
                              </p>
                              <form
                                method="post"
                                className="position-relative"
                                onSubmit={e => handleMobileLogin(e)}
                              >
                                <div className="form-group mb-2 d-flex align-items-center justify-end">
                                  <span className="text-size-20 mr-1">+91</span>
                                  <input
                                    type="phone"
                                    required={true}
                                    minLength={10}
                                    maxLength={10}
                                    name="phone"
                                    placeholder="Phone Number"
                                    onChange={e =>
                                      setPhoneNumber(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="form-group d-flex align-items-center justify-end mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-heading btn-block hover-up "
                                    name="login"
                                  >
                                    Continue
                                    {userLoading && (
                                      <FaSpinner className="mx-2 spinner" />
                                    )}
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="border p-40 cart-totals ml-30 mb-30">
                  <div className="d-flex align-items-end justify-content-between mb-30">
                    <h4>Your Order</h4>
                    <h6 className="text-muted">Subtotal</h6>
                  </div>
                  <div className="divider-2 mb-30"></div>
                  <div className="table-responsive order_table table-border-tr-none max-h:400px">
                    {loading ? (
                      <SmallCardLoader />
                    ) : (
                      <table className={"table no-border"}>
                        <tbody>
                          {handleCartItem()?.map((item, i) => (
                            <CheckoutItem item={item} key={i} />
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                {loading ? (
                  <></>
                ) : (
                  <div className="border p-40 cart-totals ml-30 mb-50  ">
                    <div className="payment_method">
                      <div className="mb-10">
                        <h5>Order summary</h5>
                      </div>
                      <div className="mb-30">
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Sub total</p>
                          <p>
                            {currencyCode}{" "}
                            {userCart?.itemsPrice
                              ? userCart?.itemsPrice
                              : itemTotal}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Shipping charge</p>
                          <p>
                            {currencyCode}{" "}
                            {userCart?.shippingPrice
                              ? userCart?.shippingPrice
                              : 0}
                          </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-20">
                          <h6>Total</h6>
                          <h6>
                            {currencyCode}{" "}
                            {userCart?.itemsPrice
                              ? userCart?.itemsPrice
                              : itemTotal}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default CheckoutLogin
