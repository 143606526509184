import {
  //cart
  ADD_OR_REMOVE,
  ADD_OR_REMOVE_FAILD,
  ADD_OR_REMOVE_SUCCESS,
  GET_USER_CART,
  GET_USER_CART_FAIL,
  GET_USER_CART_SUCCESS,

  //coupon
  COUPON_VALIDATE,
  COUPON_VALIDATE_SUCCESS,
  COUPON_VALIDATE_FAIL,

  //order
  MAKE_ORDER,
  MAKE_ORDER_SUCCESS,
  MAKE_ORDER_FAIL,
  GET_USER_ORDERS,
  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  RE_ORDER,
  RE_ORDER_SUCCESS,
  RE_ORDER_FAIL,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  RETURN_ORDER,
  RETURN_ORDER_SUCCESS,
  RETURN_ORDER_FAIL,

  //payment
  CREATE_RAZORPAY_ORDER,
  CREATE_RAZORPAY_ORDER_SUCCESS,
  CREATE_RAZORPAY_ORDER_FAIL,
  VERIFY_RAZORPAY_ORDER,
  VERIFY_RAZORPAY_ORDER_SUCCESS,
  VERIFY_RAZORPAY_ORDER_FAIL,

  //address
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAIL,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAIL,
  SET_DEFUALT_ADDRESS,
  SET_DEFUALT_ADDRESS_SUCCESS,
  SET_DEFUALT_ADDRESS_FAIL,

  //favourite
  GET_FAVOURITE_ITEMS,
  GET_FAVOURITE_ITEMS_SUCCESS,
  GET_FAVOURITE_ITEMS_FAIL,
  ADD_OR_REMOVE_FAV,
  ADD_OR_REMOVE_FAV_SUCCESS,
  ADD_OR_REMOVE_FAV_FAILD,

  //quick purchase
  GET_QUICKLIST_ITEMS,
  GET_QUICKLIST_ITEMS_SUCCESS,
  GET_QUICKLIST_ITEMS_FAIL,

  //lcalCart
  GET_LOCAL_CART,
  GET_LOCAL_CART_SUCCESS,
  GET_LOCAL_CART_FAIL,
  BULK_CART,
  BULK_CART_ADDED,
  BULK_CART_FAIL,
  GET_LOCAL_FAVITEM,
  GET_LOCAL_FAVITEM_FAIL,
  GET_LOCAL_FAVITEM_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  //cart
  userCart: [],
  localCart: [],
  cartItems: [],
  error: {},
  loading: false,
  addOrRemoveLoad: false,
  cartAdded: "",
  bulkLoad: false,

  //coupon
  coupon: {},
  couponLoad: false,

  //order
  allOrder: [],
  order: {},
  orderLoad: false,

  //payment
  paymentInfo: {},
  paymentVerifyInfo: {},
  paymentLoad: false,

  //address
  uesrAddress: {},
  addressLoad: false,

  //favourite
  favourites: {},
  favouriteLoad: false,

  //quick purchase
  quickPurchase: {},
  quickPurchaseLoad: false,
}

const Cart = (state = INIT_STATE, action) => {
  switch (action.type) {
    //cart
    case BULK_CART:
      return {
        ...state,
        bulkLoad: true,
      }
    case GET_USER_CART:
      return {
        ...state,
        loading: true,
      }
    case GET_USER_CART_SUCCESS:
      return {
        ...state,
        userCart: action.payload,
        cartItems: action.payload?.cartItems,
        loading: false,
      }
    case GET_USER_CART_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case BULK_CART_ADDED:
      return {
        ...state,
        userCart: action.payload,
        cartItems: action.payload?.cartItems,
        localCart: [],
        bulkLoad: setTimeout(() => false, 2000),
      }
    case BULK_CART_FAIL:
      return {
        ...state,
        error: action.payload,
        bulkLoad: false,
      }

    case GET_LOCAL_CART:
      return {
        ...state,
        loading: true,
      }
    case GET_LOCAL_CART_SUCCESS:
      return {
        ...state,
        userCart: action.payload,
        localCart: action.payload,
        loading: false,
      }
    case GET_LOCAL_CART_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_OR_REMOVE:
    case RE_ORDER:
      return {
        ...state,
        addOrRemoveLoad: true,
      }
    case ADD_OR_REMOVE_SUCCESS:
      return {
        ...state,
        userCart: action.payload,
        cartAdded: action.payload.cartItems?.filter(
          i => i.varient === action.payload.myVarientId
        ),
        cartItems: action.payload.cartItems,
        addOrRemoveLoad: false,
      }
    case ADD_OR_REMOVE_FAILD:
      return {
        ...state,
        error: action.payload,
        addOrRemoveLoad: false,
      }

    //coupon
    case COUPON_VALIDATE:
      return {
        ...state,
        couponLoad: true,
      }
    case COUPON_VALIDATE_SUCCESS:
      return {
        ...state,
        coupon: action.payload,
        couponLoad: false,
      }
    case COUPON_VALIDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        couponLoad: false,
      }

    //order
    case MAKE_ORDER:
    case GET_USER_ORDERS:
    case GET_ORDER_DETAILS:
    case CANCEL_ORDER:
    case RETURN_ORDER:
      return {
        ...state,
        orderLoad: true,
      }
    case MAKE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoad: false,
      }
    case MAKE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        orderLoad: false,
      }
    case GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        allOrder: action.payload,
        orderLoad: false,
      }
    case GET_USER_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        orderLoad: false,
      }
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoad: false,
      }
    case GET_ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        orderLoad: false,
      }
    case RE_ORDER_SUCCESS:
      return {
        ...state,
        userCart: action.payload,
        cartItems: action.payload?.cartItems,
        addOrRemoveLoad: false,
      }
    case RE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        addOrRemoveLoad: false,
      }
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoad: false,
      }
    case CANCEL_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        orderLoad: false,
      }
    case RETURN_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        orderLoad: false,
      }
    case RETURN_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        orderLoad: false,
      }

    //payment
    case CREATE_RAZORPAY_ORDER:
      return {
        ...state,
        paymentLoad: true,
      }
    case VERIFY_RAZORPAY_ORDER:
      return {
        ...state,
        paymentLoad: true,
        paymentVerifyInfo: action.payload,
      }

    case CREATE_RAZORPAY_ORDER_SUCCESS:
      return {
        ...state,
        paymentInfo: action.payload,
        paymentLoad: false,
      }
    case CREATE_RAZORPAY_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        paymentLoad: false,
      }

    case VERIFY_RAZORPAY_ORDER_SUCCESS:
      return {
        ...state,
        paymentVerifyInfo: { ...state.paymentVerifyInfo, ...action.payload },
        paymentLoad: false,
      }
    case VERIFY_RAZORPAY_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        paymentLoad: false,
      }

    //address
    case GET_USER_ADDRESS:
    case DELETE_USER_ADDRESS:
    case UPDATE_USER_ADDRESS:
    case SET_DEFUALT_ADDRESS:
      return {
        ...state,
        addressLoad: true,
      }
    case GET_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        uesrAddress: action.payload,
        addressLoad: false,
      }
    case GET_USER_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        addressLoad: false,
      }
    case UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        uesrAddress: action.payload,
        addressLoad: false,
      }
    case UPDATE_USER_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        addressLoad: false,
      }
    case DELETE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        uesrAddress: action.payload,
        addressLoad: false,
      }
    case DELETE_USER_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        addressLoad: false,
      }
    case SET_DEFUALT_ADDRESS_SUCCESS:
      return {
        ...state,
        uesrAddress: action.payload,
        addressLoad: false,
      }
    case SET_DEFUALT_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        addressLoad: false,
      }

    //favourite

    case GET_FAVOURITE_ITEMS:
    case ADD_OR_REMOVE_FAV:
      return {
        ...state,
        favouriteLoad: true,
      }
    case GET_FAVOURITE_ITEMS_SUCCESS:
      return {
        ...state,
        favourites: action.payload,
        favouriteLoad: false,
      }
    case GET_FAVOURITE_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        favouriteLoad: false,
      }
    case ADD_OR_REMOVE_FAV_SUCCESS:
      return {
        ...state,
        favouritesAdd: action.payload,
        favouriteLoad: false,
      }
    case ADD_OR_REMOVE_FAV_FAILD:
      return {
        ...state,
        error: action.payload,
        favouriteLoad: false,
      }

    //quick purchase
    case GET_QUICKLIST_ITEMS:
      return {
        ...state,
        quickPurchaseLoad: false,
      }
    case GET_QUICKLIST_ITEMS_SUCCESS:
      return {
        ...state,
        quickPurchase: action.payload,
        quickPurchaseLoad: false,
      }
    case GET_QUICKLIST_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        quickPurchaseLoad: false,
      }

    default:
      return state
  }
}

export default Cart
