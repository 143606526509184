import Layout from "components/layout/Layout"
import React from "react"
import CollectionProductsList from "./collection-product-grid"

function SingleCollection() {
  return (
    <Layout parent="Home" sub="Collection" subChild="Products">
      <section className="mt-30 mb-50">
        <CollectionProductsList />
      </section>
    </Layout>
  )
}

export default SingleCollection
