import { useSelector } from "react-redux"

export const useLangSwitchquery = () => {
  const { siteLanguage, multiLanguange } = useSelector(state => ({
    siteLanguage: state.Layout.siteLanguage,
    multiLanguange: state?.SiteSettings.siteSettings?.multiLanguange,
  }))

  if (multiLanguange?.isMultiLanguage === true) {
    if (siteLanguage === "en") {
      return "Primary"
    }
    if (siteLanguage === "ar") {
      return "Secondary"
    } else return "Primary"
  } else return "Primary"
}

function useLangSwitch(primaryLang, secondaryLang) {
  if (useLangSwitchquery() === "Primary") {
    return primaryLang
  } else if (secondaryLang?.name || secondaryLang?.description) {
    return secondaryLang
  } else if (secondaryLang?.title) {
    return secondaryLang
  } else {
    return primaryLang
  }
}

export default useLangSwitch
