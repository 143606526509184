import { useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import React from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { getSingleCollection } from "store/actions"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import CustomFilterBar from "../custom-filter-bar"

const CollectionProductsList = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, singleCollection } = useSelector(state => ({
    loading: state.Products.loading,
    singleCollection: state.LayoutGroup.singleCollection,
  }))

  const CollName = params?.name?.replace(/-/g, " ")

  useEffect(() => {
    dispatch(getSingleCollection(CollName))
  }, [dispatch])

  return (
    <>
      <section className="mt-20 mb-30">
        <div className="container">
          <div className="row flex-row-reverse">
            <CustomFilterBar />
            <div className="col-12 p-4">
              <div className="shop-product-fillter">
                <div className="totall-product d-flex align-items-center"></div>
              </div>

              <div
                className="row product-grid-3"
                style={{ position: "sticky", top: "6rem" }}
              >
                {singleCollection?.products?.length === 0 ? (
                  <h3>No Products Found </h3>
                ) : (
                  <>
                    {!loading ? (
                      singleCollection?.products?.map((item, i) => (
                        <div
                          className="col-lg-1-5 col-md-3 col-6 col-sm-4"
                          key={i}
                        >
                          <ProductCardOne product={item} />
                        </div>
                      ))
                    ) : (
                      <ProductListLoader />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.products,
  productFilters: state.productFilters,
})

const mapDidpatchToProps = {
  // openCart,
  //   fetchProduct,
  // fetchMoreProduct,
}

export default connect(
  mapStateToProps,
  mapDidpatchToProps
)(CollectionProductsList)

CollectionProductsList.propTypes = {
  products: PropTypes.any,
  productFilters: PropTypes.any,
  fetchProduct: PropTypes.any,
}

//images
