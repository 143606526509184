import React from "react"
import Search from "./Search"
import { HiArrowLeft } from "react-icons/hi"
import { useHistory } from "react-router-dom"

function SearchPage() {
  const history = useHistory()
  return (
    <div className="search_page_wrap">
      <div className="search-style-2 position-relative max-w:600px mySearchPage d-flex align-items-center">
        <HiArrowLeft
          className="mx-3"
          size={"1.3rem"}
          onClick={() => history.goBack()}
        />
        <Search isMobile isSingle />
      </div>
    </div>
  )
}

export default SearchPage
