import CategorySlider from "components/sliders/Category2"
import React from "react"
import { useWindowSize } from "react-use"

function CategorySliderContainer() {
  const { width } = useWindowSize()
  return (
    <section
      style={{
        marginTop: width >= 500 ? "50px" : "0px",
        marginBottom: "80px",
      }}
    >
        {/* <div className="text-center" style={{ marginBottom: "30px" }}>
          <h3 className=" fontPlayfair hidep">Categories</h3>
          <p className="font-size-1 ">
            Shop for exquisite lamp lights online at Wooden Street and enhance
            the ambiance of your home interiors with their captivating designs.
          </p>
          <p className="font-size-12 hidep2 ">
          Shop exquisite lamp lights online at Wooden Street. Enhance ambiance!
          </p>
        </div> */}
        <div
          // className="p-2"
          style={{ width: width >= 1100 ? "100%" : "100%", margin: "auto" }}
        > 
          <div
            className={`carausel-10-columns new_cat_slider`}
            id="carausel-10-columns "
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              width:"99%"

            }}
          >
            <CategorySlider />
          </div>
        </div>
    </section>
  )
}

export default CategorySliderContainer
