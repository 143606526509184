import React from "react"
import { useWindowSize } from "react-use"

export const useCustomDevice = (platform, smComponent, lgComponent) => {
  const { width } = useWindowSize()

  switch (platform) {
    case "common":
      return lgComponent
    case "mobile":
      if (width < 768) {
        return smComponent
      }
      return <></>
    case "web":
      if (width > 768) {
        return lgComponent
      }
      return <></>
  }
}
