import SubCategoryList from "components/ecommerce/filter/subCategoryList"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import CategoryProduct2 from "components/ecommerce/filter/CategoryProduct2"
import { useDispatch, useSelector } from "react-redux"
import { getSingleCollection } from "store/actions"
import { useWindowSize } from "react-use"

function CustomFilterBar() {
  const params = useParams()
  const dispatch = useDispatch()
  const { width } = useWindowSize()

  const { singleCollection } = useSelector(state => ({
    singleCollection: state.LayoutGroup.singleCollection,
  }))

  useEffect(() => {
    if (params?.type === "collection") {
      dispatch(getSingleCollection(params?.name?.replace(/-/g, " ")))
    }
  }, [dispatch, params?.name])

  return (
    <div className={"col-12 primary-sidebar sticky-sidebar "}>
      {params?.type === "collection" && (
        <div className="page-header mt-30 mb-30 ">
          <div className={`container ${width < 768 ? "p-0" : ""}`}>
            <div>
              <img
                src={
                  singleCollection?.bannerImages?.length &&
                  singleCollection?.bannerImages[0]?.url
                }
                alt="collections"
                className="rounded-sm"
              />
            </div>
          </div>
        </div>
      )}

      {params?.type === "category" && (
        <>
          <div className="sidebar-widget widget-category-2 mb-10 custom_category_label p-0 pt-3">
            <CategoryProduct2 showCount={false} />
          </div>

          <div className="sidebar-widget widget-category-2 mb-30 custom_category_label p-0 pt-3">
            <SubCategoryList />
          </div>
        </>
      )}
    </div>
  )
}

export default CustomFilterBar
